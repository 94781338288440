import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Spin } from "antd";
// import { getUser, suspendUser, removeUser, addDoctor, removeDoctor } from "../../../redux/actions/admin/admin";




import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const Notifications = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [customData, setCustomData] = useState(false);
    const [customDataRows, setCustomDataRows] = useState([]);









// CSV
    // const [json, setJson] = useState("");


    useEffect(()=>{
//       let newData = customDataRows;

//       let obj = {
//         key: "",
//         value: "",
//       };
//       newData.push(obj);
//       setCustomDataRows(newData);


},[customDataRows])




    // const usersData = useSelector((state) => {
    //   // setJson(state.admin.users);
    //
    //   return state.admin.users;
    // });

  const  addValue = (event) => {

      let newData = customDataRows;

      let obj = {
        key: "",
        value: "",
      };
      newData.push(obj);
      setCustomDataRows(newData);
      setCustomData(!customData);
    };
    const removeIndex = (index) => {
      let newData = customDataRows;

      newData.splice(index, 1);
      setCustomDataRows(newData);
      setCustomData(!customData);
      // this.setState({ chronic: newData });
    };
    useEffect(()=>{

},[customData])

    return (
        <div className="container-fluid patient-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
              <div className="row p-2 px-3">
                <div className="col-12">
                {/*<aside className="col-12 mx-lg-1 mx-0 py-lg-3 py-2 mb-lg-0 mb-2 fixed-top h-100" style={{ background:"rgb(0, 54, 80)",borderRadius:"1em" }}>

                </aside>*/}
                <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Send Notifications</h1>
                </div>
                <div className="col-12">
                  <div className="row mw-100 m-auto">
                    <form className="needs-validation" noValidate>
                      <div className="row mw-100 m-auto">

                        <div className="col-12 col-md-4">

                            <label className="text-black registration-input-labels" for="audience"> Select Audience </label>
                              <select
                                id="audience"
                                placeholder="Select"
                                className="registration-form-control form-control"
                                required
                              >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Female">Other</option>
                              </select>



                        </div>
                        <div className="col-12 mt-3">


                              <label className="registration-input-labels" for="title">Title</label>
                              <input
                              type="text"
                              className="form-control"
                              id="title"
                              required
                            />


                        </div>
                        <div className="col-12 mt-3">
                          <div className="row justify-content-center align-items-center mw-100 m-auto mb-3 text-white">
                            <div className="col-12 col-md-6">
                                  <div className="d-grid ml-auto w-100">
                                    <label className="registration-input-labels text-black" for="comments">Text</label>
                                    <textarea id="comments" name="" rows="8" cols="50"
                                      className="text-black form-control comment-block w-100"
                                      required>
                                    </textarea>

                                    </div>

                            </div>
                            <div className="col-12 col-md-6">
                            <div className="row mw-100 m-auto">
                              <div className="col-12 registration-input-labels upload-image">
                                <p className="text-black">Upload Image</p>
                              </div>
                              <div className="col-12">
                                <div className="d-flex justify-content-center form-control align-items-end bg-white file-upload" >
                                  <label for="file-upload" className="custom-file-upload add-info-btn">
                                    <i className="fa fa-cloud-upload"></i> Choose
                                  </label>
                                  <input id="file-upload" className="form-control" type="file"/>
                                </div>

                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-5">
                          <div className="row mw-100 m-auto">
                            <div className="col-12 text-center">
                              <button className="add-info-btn" disabled={customDataRows.length > 0} onClick={(e)=>addValue(e)}>Add Custom Data</button>
                            </div>
                            <div className="col-12">
                            {customDataRows && customDataRows.length > 0 && customDataRows.map((row, index)=>

                              <div className="row mt-5">
                                <div className="col-12 col-md">
                                  <label className="registration-input-labels text-black" for="custom-key">Key</label>
                                    <input
                                    type="text"
                                    className="registration-datePicker form-control"
                                    id="custom-key"
                                    />
                                </div>
                              <div className="col-12 col-md">
                                <label className="registration-input-labels text-black" for="custom-value">Value</label>
                                  <input
                                  type="text"
                                  className="registration-datePicker "
                                  id="custome-value"
                                  />
                              </div>
                              <div className="col-12 col-md-1 actions-tab">
                                <button type="button" onClick={(index)=>removeIndex(index)} className=" mt-5">
                                  <span className="">
                                    <i className="fas fa-trash-alt text-danger"></i>
                                    </span>
                                </button>
                                <button type="button" onClick={(e)=>addValue(e)} className=" ml-4">
                                  <span className="">
                                    <i className="fas fa-plus"></i>
                                    </span>
                                </button>
                              </div>
                              </div>
                            )
                          }
                            </div>

                            <div className="col-12 text-right mt-3">
                                <button className="add-info-btn" type="submit">Send</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
                <div className="col-12 col-md-12 mt-5">
                    <div className="row mw-100 m-auto">
                        <div className="col-md-12">
                          <div className="panel panel-success">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>Notifications</th><th></th><th></th><th></th>
                              </tr>
                                <tr>
                                  <th>Time</th><th>Text</th><th>Group</th><th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>08:35 am</td>
                                  <td>test8</td>
                                  <td> </td>
                                  <td><button type="button" className="" data-toggle="modal" data-target="#commonModal"><i className="fas fa-edit text-primary"></i></button><button type="button" className=" ml-4"><span className=""><i className="fas fa-play text-success"></i></span></button></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                    </div>

                </div>
              </div>
            {/*</div>*/}
        </div>
    );
};

export default Notifications;
