import React from "react";
import {useSelector} from "react-redux";
import * as _ from 'lodash';
import moment from 'moment';


import OgpChart from "../Charts/OgpChart";
import BGCurve from "../Charts/curveChart";
import BGLogChart from "../Charts/dailyBgLogChart";



const CGMReport = (props) => {
  console.log("PROPS CGM REPORT", props);
  const {mbg, sdbg, cv, lage, cgmHistory, ogp, jIndex, mage,mValue, iqr, aac, auc,hbgi,adrr} = useSelector((state)=>{return state.bloodGlucose});


    let avgGlu = _.round(props.avg.averageGlucose, 1).toFixed(1);


    // let lastGlu=_.round(props.avg.lastReading.value, 1).toFixed(1);
    let lastAvg = props.avg.lastReading;
    console.log("DATA in cgm: ", props.avg ? props.avg : "not found data");
    let age = new Date().getFullYear() - (props.user ? new Date(props.user.user.dateOfBirth).getFullYear() : 0)

    console.log("DATA in cgm: ", props.filter ? props.filter : "not found data");
    let currentTime = moment(new Date).format('YYYY-MM-DD');
    let highHeight = "10%";
    let moderateHeight = "50%";
    let lowHeight = "40%";
    let dailyBg =[]


    let curve = []

    return (

        <div className='h-100 d-flex flex-column justify-content-between'>

            {/*<div className="chart-heading mt-3 loginNew-HCP-panel d-flex">


            </div>*/}
            {/* <div>
              <i class="fas fa-ellipsis-h"></i>
            </div> */}
            <div className="d-flex mt-3">
                <div className="text-center w-100">
                    <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                        CGM ASSESSMENT REPORT
                    </p>
                </div>
            </div>
            <div className="d-flex w-100">
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        Name:
                    </label>
                    <label className="">
                        {props.user ? props.user.user.firstName : ""} {props.user ? props.user.user.lastName : ""}
                    </label>
                </div>
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        Gender:
                    </label>
                    <label className="">
                        {props.user ? props.user.user.gender : ""}
                    </label>
                </div>
            </div>
            <div className="d-flex w-100">
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        age:
                    </label>
                    <label className="">
                        {age}
                    </label>
                </div>
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        Email:
                    </label>
                    <label className="">
                        {props.user ? props.user.user.email : ""}
                    </label>
                </div>
            </div>
            <div className="d-flex w-100">
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        Assessment Period:
                    </label>
                    <label className="">
                        {props.filter ? props.filter : (props.startDate ? props.startDate + "/" + props.endDate : "")}
                    </label>
                </div>
                <div className="col-6">
                    <label className="ml-3" style={{width: "40%"}}>
                        Generated Time:
                    </label>
                    <label className="">
                        {currentTime}
                    </label>
                </div>
            </div>
            <div className="d-flex mt-3">
                <div className="text-center w-25">
                    <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                        Blood Glucose Metrics
                    </p>
                </div>
            </div>
            <div className="row mt-3 m-0">
                <div className="col-12">
                    <div className="row m-0 text-center"
                         style={{"border": "1px solid #f0f0f0", "border-radius": "10px"}}>
                        <div className="col-4" style={{
                            "background-color": "#f5f5f5",
                            "padding": "2%",
                            "border-radius": "10px 0px 0px 10px"
                        }}>
                            <div style={{"height": "130px"}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    eHbA1c
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Estimated Glycated Hemoglobin
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    5.94%
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value &lt; 7%
                                </p>
                            </div>
                            <div className="" style={{"height": ""}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    MBG
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Mean Blood Glucose
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    {mbg} mmol/L
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value 4.3-6.3mmol/L
                                </p>
                            </div>
                        </div>
                        <div className="col-4" style={{"padding": "2%"}}>
                            <div style={{"height": "130px"}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    SDBG
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Standard Deviation of Blood Glucose
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    {sdbg} mmol/L &uarr;
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value 0.5-1.4mmol/L
                                </p>
                            </div>
                            <div className="" style={{"height": ""}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    CV
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Coefficient of Variation
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    {cv}% &uarr;
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value 18%-36%
                                </p>
                            </div>
                        </div>
                        <div className="col-4" style={{
                            "background-color": "#f5f5f5",
                            "padding": "2%",
                            "border-radius": "0px 10px 10px 0px"
                        }}>
                            <div style={{"height": "130px"}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    LAGE
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Largest Amplitude of Glycemic Excursion
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    {lage} mmol/L &uarr;
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value &lt; 5.7mmol/L
                                </p>
                            </div>
                            <div className="" style={{"height": ""}}>
                                <p className="m-0" style={{"color": "blue", "font-size": "12px", "font-weight": "600"}}>
                                    MAGE
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px",}}>
                                    Mean Amplitude of Glycemic Excursion
                                </p>
                                <p className="m-0" style={{"font-size": "16px", "font-weight": "600"}}>
                                    {mage} mmol/L &uarr;
                                </p>
                                <p className="m-0" style={{"color": "grey", "font-size": "12px"}}>
                                    Reference Value &lt; 3.9mmol/L
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mt-3">
                    <h5 className="text-center" style={{"color": "blue"}}>Time in Range</h5>
                    <div className="row ml-2">
                        <div className="col-6" style={{"height": "200px", "font-size": "10px"}}>
                            <div className="row" style={{"height": highHeight}}>
                                <div className="col-3" >
                                    <div style={{"height": "100%", "background-color": "yellow", "width": "26px"}}></div>
                                </div>
                                <div className="col-9" style={{"display": "flex", "align-items": "center"}}>- High {highHeight}</div>
                            </div>
                            <div className="row" style={{"height": moderateHeight}}>
                                <div className="col-3">
                                    <div style={{"height": "100%", "background-color": "green", "width": "26px"}}></div>
                                </div>
                                <div className="col-9" style={{"display": "flex", "align-items": "center"}}>- Moderate {moderateHeight}</div>
                            </div>
                            <div className="row" style={{"height": lowHeight}}>
                                <div className="col-3">
                                    <div style={{"height": "100%", "background-color": "red", "width": "26px"}}></div>
                                </div>
                                <div className="col-9" style={{"display": "flex", "align-items": "center"}}>- Low {lowHeight}</div>
                            </div>

                        </div>
                        <div className="col-6"  style={{"font-size": "10px"}}>
                            <div className="row" style={{"height": highHeight}}>
                                <div className="col-3"  style={{"display": "flex", "align-items": "center"}}>
                                    <div style={{"height": "15px", "background-color": "yellow", "width": "15px"}}></div>
                                </div>
                                <div className="col-9"  style={{"display": "flex", "align-items": "center"}}>High≥10.0mmol/L Reference Value(&lt;25%)</div>
                            </div>
                            <div className="row" style={{"height": moderateHeight}}>
                                <div className="col-3"  style={{"display": "flex", "align-items": "center"}}>
                                    <div style={{"height": "15px", "background-color": "green", "width": "15px"}}></div>
                                </div>
                                <div className="col-9"  style={{"display": "flex", "align-items": "center"}}>Moderate3.9-10.0mmol/L Reference Value(&gt;70%)</div>
                            </div>
                            <div className="row" style={{"height": lowHeight}}>
                                <div className="col-3" style={{"display": "flex", "align-items": "center"}}>
                                    <div style={{"height": "15px", "background-color": "red", "width": "15px"}}></div>
                                </div>
                                <div className="col-9" style={{"display": "flex", "align-items": "center"}} >Low≤3.9mmol/L Reference Value(&lt;4%)</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-6">

                </div>
            </div>
            <div className="row mt-3 m-0">
                <div className="col-12" style={{"padding": "2% !important"}}>
                    <div className="p-4" style={{"font-size": "12px", "background": "lightgray", "border-radius": "15px"}}>
                    <p>Metrics Explaination</p>
                    <p>1. eHbA1c is estimated HbA1c according to CGM readings. It is used for evaluating the overall blood glucose level. For most of diabetic patients, the target value should be controlled under 7%.</p>
                    <p>2. MBG is the average value of all blood glucose data collected during CGM monitoring period. It is used for overall glucose level evaluation. Normal reference value is around 4.3 - 6.6 mmo/L.</p>
                    <p>3. SDBG is the standard deviation (SD) of measured value during CGM monitoring. It is used for evaluating the extent of overall deviation from average blood glucose value, also called as blood glucose fluctuations. Normal reference value is around 0.5 - 1.4 mmol/L.</p>
                    <p>4. CV is the ratio of blood glucose SD to average blood glucose expressed in percentage. It is used for evaluating blood glucose fluctuation. Normal reference value is around 18% - 36%.</p>
                    <p>5. LAGE is the difference between the highest and lowest blood glucose value. This index can reflect diabetes patients' lagest blood glucose fluctuations in a day. Normal reference value is under 5.7 mmol/L.</p>
                    <p>6. MAGE is the average value of all blood glucose flucations greater than 1SD during CGM monitoring. Normal reference value is under 3.9 mmol/L.</p>
                    <p>7. Time in Range (TIR) refers to the amount of time in a 24-hour period where blood glucose is within the target range (3.9-10.0 mmol/L). In this context, TIR refers to the duration where blood glucose value lies outside the target range. Therefore, it also inclues the time above target range (TAR) and the time below target range (TBR). For most diabetic patients, it is recommended to ensure the TIR is greater than 70%. Please notice the process of controlling target should be individualized.</p>
                    <p>8. Low Blood Glucose Index is used to evaluate the risk of hypoglycemia.</p>
                </div>
                </div>
            </div>
            <div className="d-flex mt-3">
                <div className="text-center w-25">
                    <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                        More BG Metrics
                    </p>
                </div>
            </div>
            <div className="row mt-3 m-0">
                <div className="col-12" style={{"padding": "2% !important"}}>
                    <table className="table table-bordered table-striped font-size-small-1">
                        <tr>
                            <td>Metrics</td>
                            <td>Result</td>
                            <td>Reference Value</td>
                            <td>Metric Explaination</td>
                        </tr>
                        <tr>
                            <td>M-Value</td>
                            <td>{mValue}</td>
                            <td className="text-center">Good Control 0-18 <br/>
                                Fair Control 18-32<br/>
                                Poor Control >32</td>
                            <td>M-Value means the average of the logarithmic transformation for the obtained blood glucose data and the reference value of blood glucose level. It is used for evaluating the reference value of swinging blood glucose.</td>
                        </tr>
                        <tr>
                            <td>J-index</td>
                            <td>{jIndex}</td>
                            <td className="text-center">Very Good Control 10-20<br/>
                                Good Control 20-30<br/>
                                Fair Control 30-40<br/>
                                Poor Control >40</td>
                            <td>0.324×(MBG+SDBG)²，J-Index is the index which describes the blood glucose stablity</td>
                        </tr>
                        <tr>
                            <td>Inter-quartile Range(mmol/L)</td>
                            <td>{iqr}</td>
                            <td className="text-center">0.7-1.6</td>
                            <td>IQR is a method in descriptive statistics to determine the difference between the third quartile and the first quartile. Just like the variance and standard deviation, it represent the dispersion of various variables in statistical data, but IQR is a more robust statistical method.</td>
                        </tr>
                        <tr>
                            <td>Area above Curve</td>
                            <td>{aac}</td>
                            <td className="text-center"></td>
                            <td>AAC5.6 is the area on the curve of blood glucose 5.6 mmol/L.</td>
                        </tr>
                        <tr>
                            <td>Area under Curve</td>
                            <td>{auc}</td>
                            <td className="text-center">&lt; 0.9</td>
                            <td>AUC5.6 is the area under the curve of blood glucose 5.6 mmol/L.</td>
                        </tr>
                        <tr>
                            <td>High Blood Glucose Index</td>
                            <td>{hbgi}</td>
                            <td className="text-center"></td>
                            <td>HBGI is used to accurately evaluate the risk of severe hyperglycemia.</td>
                        </tr>
                        <tr>
                            <td>Average Daily Risk Range</td>
                            <td>{adrr}</td>
                            <td className="text-center">Low Risk &lt; 20<br/>
                                Moderate Risk 20-40<br/>
                                High Risk &gt;40</td>
                            <td>Regardless of whether for hypoglycemia of hyperglycemia, ADRR is the best predictive methos. Furthermore, this predictive ability is not effected by the type of diabetes.</td>
                        </tr>
                        <tr>
                            <td>Glycemic Risk Assessment Diabetes Equation</td>
                            <td>9.95</td>
                            <td className="text-center">&lt; 5</td>
                            <td>GRADE calculates the weights of the equation to high BG, moderate BG and low BG. If BG deviated further from the moderate value, its weight would be greater. The advantage of GRADE is to reflect the severe extent of hyperglycemia and hypoglycemia.</td>
                        </tr>

                        <tr>
                            <td>Mean Amplitude of Glycemic Excursion(mmol/L/hour)</td>
                            <td>1.29</td>
                            <td className="text-center"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mean of Daily Difference(mmol/L)</td>
                            <td>2.49</td>
                            <td className="text-center">&lt; 5</td>
                            <td>MODD is the average level of the absolute value of the difference between the corresponding measured values within 2 days. It is used to evaluate the extent of daily BG fluctuation, in order to refelct the repeatability of everyday BG.</td>
                        </tr>

                        <tr>
                            <td>Continuous Overlapping Net Glycemic Action</td>
                            <td>2.99</td>
                            <td className="text-center"></td>
                            <td>CONGA4 is the standard deviation of the difference of BG in 4-hour intervals.</td>
                        </tr>

                    </table>
                </div>
            </div>

            <div className="row mt-3 m-0">
              <div className="col-12">
              <div className="text-center w-25">
                  <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                      OGP
                  </p>
              </div>
              </div>
              <div className="col-12">
                <OgpChart data={ogp}/>
              </div>
            </div>

            {/* bg curve*/}

            <div className="row mt-3 m-0">
              <div className="col-12">
              <div className="text-center w-25">
                  <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                  Daily BG Curve
                  </p>
              </div>
              </div>
              <div className="col-12">
              <div className="row">
              {cgmHistory && cgmHistory.length>0 && cgmHistory.map((week, index)=> (
              <div className="mt-4" key={index+1}>
                {week && week.readings && week.readings[0].length>0?<BGCurve chartId={"BloodGlucoseCurve"+ index}  data={week}/>:null}
              </div>
            ))} </div>

              </div>
            </div>


            <div className="row mt-3 m-0">
              <div className="col-12">
              <div className="text-center w-25">
                  <p className="bg-primary px-2 py-2 font-weight-bold text-white">
                  Daily BG Log
                  </p>
              </div>
              </div>
              <div className="col-12">
              <div className="row">
              {cgmHistory.map((week, index)=> (
                <div className="w-100" key={index+1}>
                  {week && week.readings && week.readings[0].length>0?<BGLogChart chartId={"HistoryReport"+ index}  data={week}/>:null}
                </div>
              ))}
            </div>

              </div>
            </div>




            {/*<div className="d-flex mt-3 w-100">*/}
            {/*    <div className="col-6">*/}
            {/*        <label className="ml-3" style={{width: "40%"}}>*/}
            {/*            age:*/}
            {/*        </label>*/}
            {/*        <label className="">*/}
            {/*            {age}*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="col-6">*/}
            {/*        <label className="ml-3" style={{width: "40%"}}>*/}
            {/*            Email:*/}
            {/*        </label>*/}
            {/*        <label className="">*/}
            {/*            {props.user ? props.user.user.email : ""}*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex mt-3 w-100">*/}
            {/*    <div className="col-6">*/}
            {/*        <label className="ml-3" style={{width: "40%"}}>*/}
            {/*            Assessment Period:*/}
            {/*        </label>*/}
            {/*        <label className="">*/}
            {/*            {props.filter ? props.filter : (props.startDate ? props.startDate + "/" + props.endDate : "")}*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="col-6">*/}
            {/*        <label className="ml-3" style={{width: "40%"}}>*/}
            {/*            Generated Time:*/}
            {/*        </label>*/}
            {/*        <label className="">*/}
            {/*            {currentTime}*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex justify-content-between px-3 mb-4">*/}

            {/*</div>*/}
        </div>


    );
};

export default CGMReport;
