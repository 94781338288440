import React,{useState} from 'react';
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from 'antd';
import moment from "moment";



const SensorTable = ({row,count,onClick, index}) => {
    const dispatch = useDispatch();
    const docToken = useSelector((state) => state.auth.token);
    const [collapse, setCollapse] = useState(false);
    const [loading,setLoading]=useState(false);

    //Handling View btn
const timeEdit =(time)=>{
  // // console.log("TYPE OF TIME", typeof(time));
  // // console.log("AFTER NEW DATE",typeof(new Date(time)));
  let date = new Date(time)
  date = moment(date).format("DD-MM-YYYY");
  return date;
}

  return (
        <>

                <tbody key={count} style={{textAlign:'center',width:"100%",height:"100%"}}>
                      <tr key={index}>


                            <td>{timeEdit(row.time)}</td>
                            <td>{row.sessionId}</td>
                            <td>{row.type}</td>
                            <td>{row.code}</td>

                            <td style={{maxWidth:"300px", display:"flex", margin:"auto"}}>{row.description}</td>
                            <td>{row.device}</td>

                            {/*<td className='text-center'>
                              <button onClick={onClick} type="button" data-toggle="collapse" data-target={`#collapseExample${count}`} aria-expanded="false" aria-controls="collapseExample"><i className={"fas fa-download"}></i></button>
                            </td>*/}
                        </tr>
                </tbody>
        </>
    );
};

export default SensorTable;
