import React, { useEffect,useState } from "react";
import { Spin, DatePicker } from "antd";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
// import {fetchExportData} from "../../../redux/actions/export";
import { useSelector, useDispatch } from "react-redux";
import {encrypt,decrypt} from "../../utils/encryption";
// const WEIGHT = require("../../../assets/GRx Weight Scale-2.jpeg");
// const BP = require("../../../assets/blood_pressure_meter.png");
// const TEMP = require("../../../assets/GRx Forehead Thermometer Pro.jpg");
// const PO = require("../../../assets/GRx Fingertip Pulse Oximeter.png");
// const Nexus= require("../../../assets/products/GlucoRxNexusMeter.png");
import ovrview from "../../Assets/Sidebar-Icons/overview.svg";
import paterns from "../../Assets/Sidebar-Icons/patterns.svg";
import trnds from "../../Assets/Sidebar-Icons/trends.svg";
import ovrlays from "../../Assets/Sidebar-Icons/overlays.svg";
import histry from "../../Assets/Sidebar-Icons/history.svg";
import compre from "../../Assets/Sidebar-Icons/compare.svg";
import statistcs from "../../Assets/Sidebar-Icons/statistics.svg";
import Agp from "../../Assets/Sidebar-Icons/Agp.svg";

const PDF =  require("../../Assets/pdf.png");

const ExportData = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const device = useSelector((state) =>(state.devices.devices));
  const { RangePicker } = DatePicker;
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [days, setDays] = useState("30days");
  const [bg, setBg] = useState(true);
  const [bp, setBp] = useState(false);
  const [weight, setWeight] = useState(false);
  const [temp,setTemp]=useState(false);
  const [spo2,setSpo2]=useState(false);
  const [overview,setOverview]=useState(false);
  const [patterns,setPatterns]=useState(false);
  const [trends,setTrends]=useState(false);
  const [overlay,setOverlay]=useState(false);
  const [historyTab,setHistoryTab]=useState(false);
  const [compare,setCompare]=useState(false);
  const [statistics,setStatistics]=useState(false);
  const [agp,setAgp]=useState(false);
  const [showButton,setShowButton]=useState(false);









   //Fetch Export Data
  //  useEffect(()=>{
  //   handleExportData()
  //   },[])

  const handleExportData=async()=>{
    if(overview === true||
    patterns=== true ||
    trends=== true ||
    overlay === true||
    historyTab === true||
    compare === true||
    statistics === true||
    agp === true){
      let data={time:days,start:startDate,end:endDate,
        overview:overview,patterns:patterns,
        trends:trends,overlay:overlay,historyTab:historyTab,compare:compare,
       statistics:statistics,agp:agp,}
       data=JSON.stringify(data);
              data=encrypt(data);
              localStorage.setItem("export",data);
              document.getElementById('closebtn').click();
             history({
                          pathname:"/export-data",

                        });
    }

    // //("data=",data)
    // try {
    //   setIsLoading(true);
    //   let response= await dispatch( fetchExportData(data))
    //         setIsLoading(false);
    //         data=JSON.stringify(data)
    //         data=encrypt(data)
    //         localStorage.setItem("export",data)
    //        history({
    //           pathname:"/export-data",

    //         });
    //         //("printing")
    // } catch (error) {
    //     setIsLoading(false);

    // }

  }



  //Handle Filters
  const handleDaysFilter = (d) => {
    setDays(d);
    setStartDate("");
    setEndDate("");
  };
  //Handle Date Picker
  const handleDateChange = (val) => {
    if (val && val.length > 0) {
      setStartDate(val[1].format("YYYY-MM-DD").toString());
      setEndDate(val[0].format("YYYY-MM-DD").toString());
      setToDate(val[1].format("YYYY-MM-DD").toString());
      setFromDate(val[0].format("YYYY-MM-DD").toString());
      setDays("")
    }

  };


  return (
    <div className="col-12">
      <button
        type="button"
        class="p-0 pdf-btn"
        data-toggle="modal"
        data-target="#exportModal"
        style={{background: "none"}}
      >
      <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
      <p className="mt-1">Export</p>

        {/* <i class="fas fa-file-export"></i>*/}
      </button>


      <div
        class="modal fade"
        id="exportModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Export
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closebtn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div className="container">
                <div className="d-flex flex-row justify-content-center">
                  <button
                    className={
                      startDate === "" && days === "7days"
                        ? "bg-primary text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("7days");
                    }}
                  >
                    7 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "14days"
                        ? "bg-primary text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("14days");
                    }}
                  >
                    14 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "30days"
                        ? "bg-primary text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("30days");
                    }}
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "90days"
                        ? "bg-primary text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("90days");
                    }}
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      startDate !== ""
                        ? "bg-primary text-white rounded px-2 dropdown"
                        : "bg-light rounded px-2 dropdown"
                    }
                    onClick={() => {
                      setDropMenu(!dropMenu);
                    }}
                  >
                    Range
                  </button>
                </div>
                <div className="row text-center mt-3 justify-content-center">
                  <div className="col-12">
                    <div className={dropMenu ? " d-block" : " d-none"}>
                      <RangePicker
                        defaultValue={
                          startDate && [moment(startDate), moment(endDate)]
                        }
                        onChange={(val) => {
                          handleDateChange(val);
                          setShowButton(!showButton);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center align-items-center my-5'>

                  <div className='col-12'>
                     <div className='row justify-content-center pdf-device'>
                        <div className='col-3'>
                       <button className={
                      overview === true
                      ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
                      : " btnImgColor rounded px-3 mr-2 py-3"
                    } onClick={()=>{
                        setOverview(!overview);


                      }}>
                         <div className="example example-cover row">
                         <div className="col-12">
                         <img src={ovrview} size="20px"></img>
                         </div>
                         <div className="col-12">

                          <span class="d-none d-md-inline">Overview</span>
                          </div>
                        </div>
                      </button>
                        </div>



                        <div className='col-3'>
                          <button className={
                         overlay === true
                         ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setOverlay(!overlay)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={ovrlays} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span class="d-none d-md-inline">Overlays</span>
                             </div>
                           </div>
                         </button>
                        </div>

                        <div className='col-3'>
                         <button className={
                        historyTab === true
                        ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
                        : " btnImgColor rounded px-3 mr-2 py-3"
                      } onClick={()=>{
                          setHistoryTab(!historyTab)
                        }}>
                           <div className="example example-cover row">
                           <div className="col-12">
                           <img src={histry} size="20px"></img>
                           </div>
                           <div className="col-12">

                            <span class="d-none d-md-inline">History</span>
                            </div>
                          </div>
                        </button>
                        </div>

                        <div className='col-3'>
                          <button className={
                         compare === true
                         ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setCompare(!compare)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={compre} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span class="d-none d-md-inline">Compare</span>
                             </div>
                           </div>
                         </button>
                        </div>



                        <div className='col-3'>
                        <button className={
                       agp === true
                       ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
                       : " btnImgColor rounded px-3 mr-2 py-3"
                     } onClick={()=>{
                         setAgp(!agp)
                       }}>
                          <div className="example example-cover row">
                          <div className="col-12">
                          <img src={Agp} size="20px"></img>
                          </div>
                          <div className="col-12">

                           <span class="d-none d-md-inline">Ogp</span>
                           </div>
                         </div>
                       </button>
                       </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              {
            overview === true||
            patterns=== true ||
            trends=== true ||
            overlay === true||
            historyTab === true||
            compare === true||
            statistics === true||
            agp === true
              ?
                <div>
                <Spin spinning={isLoading}>
                <button type="button" class="btn btn-primary" onClick={handleExportData}>
                Export
                </button>
                </Spin>
                </div>:null}


      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
