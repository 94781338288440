// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Route, Navigate } from "react-router-dom";
// import SharedLayout from "../../Components/Common/SharedLayout";
// import {logout} from "../../redux/actions/auth";
//
// class AuthenticatedRoute extends Component {
//   redirect=()=>{
//     return(
//       <Navigate to="/auth/login" />
//     )
//
//   }
//   componentDidMount() {
//     // if (localStorage.getItem("EostreAuthToken") === null) {
//     //
//     //   this.props.dispatch(logout());
//     //
//     // }
//     if(!this.props.isAuthenticated || localStorage.getItem("EostreAuthToken") === null){
//       this.redirect()
//     }
//     if(localStorage.getItem("pathname")==="/auth/login"){
//     localStorage.removeItem("pathname");
//   }
//     if(localStorage.getItem("pathname")===window.location.pathname){
//     localStorage.removeItem("pathname");
//   }
//   }
//   // componentWillReceiveProps(nextProps, nextContext) {
//   //   if (localStorage.getItem("EostreAuthToken") === null) {
//   //
//   //     this.props.dispatch(logout());
//   //
//   //   }
//   // }
//   render() {
// //console.clear();
//    const saveToken=()=>{
//
//       return(
//         <Navigate to="/auth/login" />
//       )
//      }
//
//     const { isAuthenticated, component: Component, ...rest } = this.props;
//
//     if (localStorage.getItem("EostreAuthToken") === null || localStorage.getItem("EostreAuthToken")==="") {
//       this.props.dispatch(logout(false));
//
//     }
//     return (
//       <Route
//         {...rest}
//         render={(props) =>
//
//           isAuthenticated && !!localStorage.getItem("EostreAuthToken")  ? (
//             <SharedLayout>
//               <Component {...props} />
//             </SharedLayout>
//           ) : isAuthenticated === false && !!localStorage.getItem("EostreAuthToken") ?
//           <SharedLayout>
//             <Component {...props} />
//           </SharedLayout>:(
//             saveToken()
//
//           )
//         }
//       />
//     );
//   }
// }
//
// function mapStateToProps(state) {
//   const { token } = state.auth;
//   return {
//     isAuthenticated: !!token,
//
//   };
// }
//
// export default connect(mapStateToProps)(AuthenticatedRoute);

//new try

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Navigate } from "react-router-dom";
import SharedLayout from "../../Components/Common/SharedLayout";
import { logout } from "../../redux/actions/auth";

const AuthenticatedRoute = ({children}) => {
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const dispatch = useDispatch();
  console.log("CHILDREN :", children);

  // const { component: Component, ...rest } = props;

  const redirect = () => <Navigate to="/auth/login" />;

  useEffect(() => {
    if (!isAuthenticated || localStorage.getItem("EostreAuthToken") === null) {
      redirect();
    }

    if (localStorage.getItem("pathname") === "/auth/login") {
      localStorage.removeItem("pathname");
    }

    if (localStorage.getItem("pathname") === window.location.pathname) {
      localStorage.removeItem("pathname");
    }
  }, [isAuthenticated]);

  const saveToken = () => <Navigate to="/auth/login" />;

  if (localStorage.getItem("EostreAuthToken") === null || localStorage.getItem("EostreAuthToken") === "") {
    dispatch(logout(false));
  }

  return (
    isAuthenticated && !!localStorage.getItem("EostreAuthToken")  ? (
                <SharedLayout>
                  {children}
                </SharedLayout>
              ) : isAuthenticated === false && !!localStorage.getItem("EostreAuthToken") ?
              <SharedLayout>
                {children}
              </SharedLayout>:(
                saveToken()

              )
  );
};

export default AuthenticatedRoute;
