import React from "react";
import { useState } from "react";
import OverlaysPdfExpo  from "../Charts/OverlayPdf";

const OverlayExpo = () => {
  const [dateFilter,setDateFilter]=useState(false);
  const [timeFilter,setTimeFilter]=useState(false);
  const [eventFilter,setEventFilter]=useState(false);
  const [usageFilter,setUsageFilter]=useState(false);
  return (
    <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>

    <div className="row justify-content-between align-items-center mt-2 mt-md-0">
        <div className="col-md-7 col-12">
          <div className="row justify-content-md-between justify-content-center mx-2">
            <div className='col-md-3 col-10 d-flex flex-column justify-content-center mt-md-0 mt-2 '><div class="dropdown" >
  <button class="filter-menu-btn" onClick={()=> {setDateFilter(!dateFilter)}}><span className='d-flex justify-content-between px-2'><span>Days</span><span>{dateFilter===false?<i class="fas fa-caret-up"></i>:<i class="fas fa-caret-down"></i>}</span></span></button>

  <div class={dateFilter===true?"dropdown-content d-block":"dropdown-content d-none"}>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Monday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Tuesday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Wednesday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Thursday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Friday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Saturday</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Monday</label>

  </div>
  <div className="text-center my-2">
    <button className="btn btn-primary">Apply</button>
  </div>
  </div>
</div></div>
            <div className='col-md-3 col-10 d-flex flex-column justify-content-center mt-md-0 mt-2 '>        <div class="dropdown" >
  <button class="filter-menu-btn" onClick={()=>{setTimeFilter(!timeFilter)}}><span className='d-flex justify-content-between px-2'><span>Time of Days</span><span>{timeFilter===false?<i class="fas fa-caret-up"></i>:<i class="fas fa-caret-down"></i>}</span></span></button>
  <div class={timeFilter===true?"dropdown-content d-block":"dropdown-content d-none"} >
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Day Time</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="checkbox" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Night Time</label>

  </div>
  <div className="text-center my-2">
    <button className="btn btn-primary">Apply</button>
  </div>


  </div>
</div></div>
            <div className='col-md-3 col-10 d-flex flex-column justify-content-center mt-md-0 mt-2 '>        <div class="dropdown" >
  <button class="filter-menu-btn" onClick={()=>{setEventFilter(!eventFilter)}}><span className='d-flex justify-content-between px-2'><span>Events</span><span>{eventFilter===false?<i class="fas fa-caret-up"></i>:<i class="fas fa-caret-down"></i>}</span></span></button>
  <div class={eventFilter===true?"dropdown-content d-block":"dropdown-content d-none"}  >
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input checked" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">None</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Low</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">High</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Standard Lows</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Standard Highs</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Rebound Lows</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Rebound Highs</label>

  </div>
  <div className="text-center my-2">
    <button className="btn btn-primary">Apply</button>
  </div>
  </div>
</div></div>
            <div className='col-md-3 col-10 d-flex flex-column justify-content-center mt-md-0 mt-2 '>        <div class="dropdown" >
  <button class="filter-menu-btn" onClick={()=>{setUsageFilter(!usageFilter)}}><span className='d-flex justify-content-between px-2'><span>Usage</span><span>{usageFilter===false?<i class="fas fa-caret-up"></i>:<i class="fas fa-caret-down"></i>}</span></span></button>
  <div class={usageFilter===true?"dropdown-content d-block":"dropdown-content d-none"} >

  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input checked" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">None</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Low Utilization</label>

  </div>
  <div class="form-check border-bottom border-secondary py-2 ">
    <input type="radio" class="form-check-input " id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Missed Callibration</label>

  </div>
  <div className="text-center my-2">
    <button className="btn btn-primary">Apply</button>
  </div>
  </div>
</div></div>

          </div>


        </div>
        <div className="col-md-4 col-12 mt-md-0 mt-3">
           <div className="row justify-content-md-end align-items-center justify-content-center mx-2">
             <div className='col-4 text-md-left text-center '>

<div className="d-inline">
<label class="switch">
  <input type="checkbox" checked/>
  <span class="slider round"></span>

</label>CGM</div>
             </div>

             <div className='col-6 text-md-left text-center '>
             <div className="d-inline">
             <label class="switch">
  <input type="checkbox"/>
  <span class="slider round"></span>

</label>
Calibration
</div>
             </div>

             </div>
        </div>
        </div>


    {/* <div>
      <i class="fas fa-ellipsis-h"></i>
    </div> */}



  <div className="d-flex flex-column justify-content-between  mt-5 pt-2 mb-4">
    <OverlaysPdfExpo chartId="overlayReportExpo"/>

  </div>
  </div>
  );
};

export default OverlayExpo;
