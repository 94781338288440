import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import Loading from '../../Common/Loading';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

function BGCurveChart(props) {

  const [showGraph, setShowGraph]=useState(false);
  const {user} = useSelector((state)=>{return state});

  useEffect(() => {

let chart = am4core.create(props.chartId, am4charts.XYChart);


chart.data = props.data && props.data.readings && props.data.readings[0].length > 0 && props.data.readings[0].map(record => {
  let color= record.glucose>=10.9? "#f38f20": record.glucose<= 3.9 ? "#d61a00": "#2a93fc";
// console.log("Rec", record);
  return {
    time: new Date(record.time),
    glucose: record.glucose,
    time2: record.time2,
    color: color
  }
});
// let dataLength = chart.data.length - 1;
// let startTime = chart.data && chart.data.length>0?chart.data[0].time: null;
// let endTime = chart.data && chart.data.length>0?chart.data[dataLength].time:null;

chart.dateFormatter.dateFormat = "yyyy-MM-dd";
  // Create axes
var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.startLocation = 0.5;
// dateAxis.endLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "YYYY-dd";
dateAxis.cursorTooltipEnabled = false;

// dateAxis.zoomToDates(new Date(startTime), new Date(endTime));



// Create value axis
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = "mmol/L";
valueAxis.cursorTooltipEnabled = false;





// chart.tapToActivate = true;
// chart.tapTimeout = 5000;
// chart.dragGrip.disabled = false;
// Create axes
// var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

// dateAxis.renderer.grid.template.location = 0;
// dateAxis.renderer.minGridDistance = 30;


// Create series

let lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "glucose";
lineSeries.dataFields.dateX = "time";
// lineSeries.showTooltipOn = "always"
// lineSeries.tooltipText = "X: {valueX} / Y: {valueY}";

// lineSeries.name = "Sales";
lineSeries.strokeWidth = 1;
lineSeries.fillOpacity = 0;

lineSeries.sequencedInterpolation = false;

let bullet = lineSeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 2;
    bullet.propertyFields.fill = "color";

    // bullet.fill = ("#2a93fc");
    bullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";;

// lineSeries.stacked = true;

// var fillModifier = new am4core.LinearGradientModifier();
// fillModifier.opacities = [1, 0];
// fillModifier.offsets = [0, 1];
// fillModifier.gradient.rotation = 90;
// lineSeries.segments.template.fillModifier = fillModifier;
// bullet.adapter.add("fill", (fill, target)=> {
//   if (target.dataItem) {
//     for(let i = 0; i < chart.data.length; i++) {
//       if (
//         target.dataItem.valueY <= 4
//       ) {
//         return am4core.color("yellow");
//       } else if(target.dataItem.valueY >= 12){
//         return am4core.color("#ff008dd9");
//
//       }else{
//         return am4core.color("#2a93fc");
//
//       }
//     }
//   }
//   return fill;
// });

// chart.scrollbarX = new am4core.Scrollbar();
// chart.scrollbarX.parent = chart.bottomAxesContainer;
// chart.scrollbarX.thumb.minWidth = 5;
// chart.scrollbarX.startGrip.disabled = true;
// chart.scrollbarX.endGrip.disabled = true;

let range = valueAxis.axisRanges.create();
range.value = 0;
// range.endValue = user.userDetails.low;
range.endValue = 3.9;

range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
// range1.value = user.userDetails.high;
range1.value = 10.9;

range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

// chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});




return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
},[props.data, props.chartId]);
let startDate=moment(new Date(props.data.weekStartDate)).format('DD/MM');
let endDate=moment(new Date(props.data.weekStartDate)).add(1, 'days').format('DD/MM').toString();
  return (
<div className="ml2">
    <div className={showGraph?"d-none":" w-100 p-5"}>                    <Loading/>
    </div>
    <div className={showGraph?"":"d-none"}>

  <p className="pb-3 ml-5">From: {startDate}  To: {endDate}</p>

        <div id={props.chartId} style={{ width: "300px", minHeight: "200px" }}></div>
  </div>
  </div>

  );
}

export default BGCurveChart;
