import React,{useEffect} from "react";
 import { useNavigate, useLocation } from "react-router-dom";

// import ClinicianDashboard from "../clinician-dashboard";
// import PatientDashboard from "../patient-dashboard";
import ReportsPage from "../../Pages/ReportsPage";

import AdminPage from "../../Pages/AdminPage";
import SubAdmins from "../../Pages/SubAdmins";
import ViewEngineer from "../Admin/ViewEngineers/ViewEngineerComp"

import MultipleRoles from "../../Components/Auth/MultipleRolesComp";

import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import UserRoles from "../../utils/user-roles";
import DocumentTitles from "../../utils/document-titles";
import RoleModal from "./ChooseRoleModal.jsx"
import {updateUserRole} from "../../redux/actions/user";

// import DoctorDashboard from "../doctor-dashboard";
export default function Dashboard() {
  document.title = DocumentTitles.dashboard;
  const { isLoading, userDetails, selectedUser } = useSelector((state) => state.user);
  const history = useNavigate();
  const dispatch = useDispatch();




// useEffect(()=>{
//   // console.log("CHANGED");
// },[userDetails.length])

  const handleApp =(e)=> {

  }
  if (!userDetails) {
    return null;
  }

   if (userDetails &&
      userDetails.user && (userDetails.user.role.includes(0)===true || userDetails.user.role.includes(1)===true) && userDetails.user.role.includes(4)===false ) {

  return (
    <Spin spinning={isLoading} style={{height:"100%"}}>
      <AdminPage admin={true}/>
    </Spin>
  );
}

if (userDetails &&
   userDetails.user && (userDetails.user.role.includes(6)===true) && userDetails.user.role.includes(4)===false) {

return (
 <Spin spinning={isLoading} style={{height:"100%"}}>
   <SubAdmins admin={true}/>
 </Spin>
);
} else if(userDetails &&
   userDetails.user && userDetails.user.role.length === 1 && userDetails.user.role.includes(5)===true ) {
     return (
<ViewEngineer/>
)
}
if(userDetails &&
   userDetails.user &&
   userDetails.user.role.length===2 &&
     userDetails.user.role.includes(3)===true && userDetails.user.role.includes(4)===true){
       let usr = {
       email: userDetails.user.email,
       firstName: userDetails.user.firstName,
       lastName: userDetails.user.lastName,
       status: userDetails.user.status,
       role: [4],
       gender: userDetails.user.geder,
       dateOfBirth: userDetails.user.dateOfBirth,
       postcode: userDetails.user.postcode,
       address: userDetails.user.address,
       country: userDetails.user.country,
       town: userDetails.user.town,
       phone: userDetails.user.phone,
       app: "modus"
   };
       dispatch(updateUserRole({user:usr}));
       return (
         <Spin spinning={isLoading} style={{height:"100%"}}>
         <ReportsPage/>
         </Spin>
       );


} else if(userDetails &&
   userDetails.user &&
   userDetails.user.role.length>1 &&
     userDetails.user.role.includes(4)===true){

  return(
    <RoleModal/>
  )
}

// if(userDetails &&
//    userDetails.user &&
//    userDetails.user.role.length === 1 &&
//      userDetails.user.role.includes(4)===true){
//   return(
//     <Spin spinning={isLoading} style={{height:"100%"}}>
//
//
//       {/*userDetails.role === UserRoles.CLINICIAN && <ClinicianDashboard />*/}
//       <ReportsPage/>
//       {/*userDetails.role === UserRoles.DOCTOR && <DoctorDashboard/>*/}
//
//     </Spin>
//   )
// }




return (
  <Spin spinning={isLoading} style={{height:"100%"}}>


    {/*userDetails.role === UserRoles.CLINICIAN && <ClinicianDashboard />*/}
    <ReportsPage/>
    {/*userDetails.role === UserRoles.DOCTOR && <DoctorDashboard/>*/}

  </Spin>
);
}
