import {
  FETCH_COMPARE_RIGHT_OVERLAY_START,
  FETCH_COMPARE_RIGHT_OVERLAY_SUCCESS,
  FETCH_COMPARE_RIGHT_OVERLAY_FAILED,
  FETCH_COMPARE_LEFT_OVERLAY_START,
  FETCH_COMPARE_LEFT_OVERLAY_SUCCESS,
  FETCH_COMPARE_LEFT_OVERLAY_FAILED,
  FETCH_COMPARE_BG_RIGHT_START,
  FETCH_COMPARE_BG_RIGHT_SUCCESS,
  FETCH_COMPARE_BG_RIGHT_FAILED,
  FETCH_COMPARE_BG_LEFT_START,
  FETCH_COMPARE_BG_LEFT_SUCCESS,
  FETCH_COMPARE_BG_LEFT_FAILED,
  FETCH_COMPARE_SENSOR_RIGHT_START,
  FETCH_COMPARE_SENSOR_RIGHT_SUCCESS,
  FETCH_COMPARE_SENSOR_RIGHT_FAILED,
  FETCH_COMPARE_SENSOR_LEFT_START,
  FETCH_COMPARE_SENSOR_LEFT_SUCCESS,
  FETCH_COMPARE_SENSOR_LEFT_FAILED,
  FETCH_COMPARE_LEFT_HISTORY_START,
  FETCH_COMPARE_LEFT_HISTORY_SUCCESS,
  FETCH_COMPARE_LEFT_HISTORY_FAILED,
  FETCH_COMPARE_RIGHT_HISTORY_START,
  FETCH_COMPARE_RIGHT_HISTORY_SUCCESS,
  FETCH_COMPARE_RIGHT_HISTORY_FAILED,
} from "../actionTypes/compare";
import moment from "moment";
import {fetchOverlayData} from "./overlay";
import {fetchBgData, fetchSensorData} from "./blood-glucose";
import {fetchHistoryData} from "./history";





// import {encrypt, decrypt} from "../../../utils/encryption";

export const fetchCompareRightData = (data) => async(dispatch) => {
  let overlay;
  let bloodGlucose;
  let history;
  let sensor;


try {

overlay = await dispatch(fetchOverlayCollection(data, true));
bloodGlucose = await dispatch(fetchBGCollection(data, true));
history = await dispatch(fetchHistoryCollection(data, true));
sensor = await dispatch(fetchSensorCollection(data, true));





  return {overlay,bloodGlucose,history,sensor};
} catch (error) {
return false;

}
};

export const fetchCompareLeftData = (data) => async(dispatch) => {
  let overlay;
  let bloodGlucose;
  let history;
  let sensor;


try {

overlay = await dispatch(fetchOverlayCollection(data, false));
bloodGlucose = await dispatch(fetchBGCollection(data, false));
history = await dispatch(fetchHistoryCollection(data, false));
sensor = await dispatch(fetchSensorCollection(data, false));





  return {overlay,bloodGlucose,history,sensor};
} catch (error) {
return false;

}
};

export const fetchOverlayCollection = (data, right) => async(dispatch)=>{
  if(right === true){
    dispatch({
      type: FETCH_COMPARE_RIGHT_OVERLAY_START,
    });
  } else {
    dispatch({
      type: FETCH_COMPARE_LEFT_OVERLAY_START,
    });
  }

  try {

     let overlays = await dispatch(fetchOverlayData(data)) ;
     if(right === true){
       dispatch({ type: FETCH_COMPARE_RIGHT_OVERLAY_SUCCESS, payLoad: overlays });
     } else {
       dispatch({ type: FETCH_COMPARE_LEFT_OVERLAY_SUCCESS, payLoad: overlays });
     }


    return overlays;
  } catch (error) {
    if(right === true){
      dispatch({
        type: FETCH_COMPARE_RIGHT_OVERLAY_FAILED,
      });
    } else {
      dispatch({
        type: FETCH_COMPARE_LEFT_OVERLAY_FAILED,
      });
    }


  }
}

export const fetchBGCollection = (data, right) => async(dispatch)=>{
  if(right === true){
    dispatch({
      type: FETCH_COMPARE_BG_RIGHT_START,
    });
  } else {
    dispatch({
      type: FETCH_COMPARE_BG_LEFT_START,
    });
  }


  try {
    let bg = await dispatch(fetchBgData(data)) ;

    if(right === true){
      dispatch({ type: FETCH_COMPARE_BG_RIGHT_SUCCESS, payLoad: bg.data.data });

    } else {
      dispatch({ type: FETCH_COMPARE_BG_LEFT_SUCCESS, payLoad: bg.data.data });

    }


    return bg.data.data;
  } catch (error) {
    if(right === true){
      dispatch({
        type: FETCH_COMPARE_BG_RIGHT_FAILED,
      });
    } else {
      dispatch({
        type: FETCH_COMPARE_BG_LEFT_FAILED,
      });
    }


  }
}

export const fetchSensorCollection = (data, right) => async(dispatch)=>{
  if(right === true){
    dispatch({
      type: FETCH_COMPARE_SENSOR_RIGHT_START,
    });
  } else {
    dispatch({
      type: FETCH_COMPARE_SENSOR_LEFT_START,
    });
  }


  try {
    let sensor = await dispatch(fetchSensorData()) ;

    if(right === true){
      dispatch({ type: FETCH_COMPARE_SENSOR_RIGHT_SUCCESS, payLoad: sensor.data.data });

    } else {
      dispatch({ type: FETCH_COMPARE_SENSOR_LEFT_SUCCESS, payLoad: sensor.data.data });

    }


    return sensor.data.data;
  } catch (error) {
    if(right === true){
      dispatch({
        type: FETCH_COMPARE_SENSOR_RIGHT_FAILED,
      });
    } else {
      dispatch({
        type: FETCH_COMPARE_SENSOR_LEFT_FAILED,
      });
    }


  }
}


export const fetchHistoryCollection = (data, right) => async(dispatch)=>{
  if(right === true){
    dispatch({
      type: FETCH_COMPARE_RIGHT_HISTORY_START,
    });
  } else {
    dispatch({
      type: FETCH_COMPARE_LEFT_HISTORY_START,
    });
  }

  try {

     let histories = await dispatch(fetchHistoryData(data)) ;
     console.log("HISTORIES", histories);
     if(right === true){
       dispatch({ type: FETCH_COMPARE_RIGHT_HISTORY_SUCCESS, payLoad: histories });
     } else {
       dispatch({ type: FETCH_COMPARE_LEFT_HISTORY_SUCCESS, payLoad: histories });
     }


    return histories;
  } catch (error) {
    if(right === true){
      dispatch({
        type: FETCH_COMPARE_RIGHT_HISTORY_FAILED,
      });
    } else {
      dispatch({
        type: FETCH_COMPARE_LEFT_HISTORY_FAILED,
      });
    }


  }
}
