import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import {
        getAdmins,
        checkEmail,
        addAdmin,
        removeAdmin,
        activateUser,
        suspendUser,
        makingAdmin,
        makingPatient,
        makingEngineer,
        makingClinicalAdmin,
        makingEngineerAdmin,
        removeRole
      } from "../../../../redux/actions/admin/admin";

 import { useNavigate, useLocation } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";
import UserDetails from "./userstatchart.js";
import countryList from './CountriesList';


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const Admins = (props) => {

  const list = useSelector((state) => {return (state.admin.admins)});
  const {modus2, admins} = useSelector((state) => {return (state.admin.admins)});
  // const {role} = useSelector((state) => {return (state.user.userDetails.user)});
  const {userDetails} = useSelector((state) => {return (state.user)});


    const dispatch = useDispatch();
    const history = useNavigate();

    const [adminsList, setAdminsList] = useState([]);
    // const [role, setRole] = useState("admin");

    const [actModal, setActModal] = useState(false);
    const [toActivate, setToActivate] = useState("");

    const [removeRoleModal, setRemoveRoleModal] = useState(false);
    const [toRoleRemove, setToRoleRemove] = useState("");
    const [toRoleRemoveId, setToRoleRemoveId] = useState("");


    const [idAvail, setIdAvail] = useState(null);
    const [idMessage, setIdMessage] = useState("");


    const [placeHolder, setPlaceHolder] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [susModal, setSusModal] = useState(false);
    const [toSuspend, setToSuspend] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [instance, setInstance] = useState("");
    const [prevApp, setPrevApp] = useState(modus2);
    const [toEdit, setToEdit] = useState("");
    const [showSave, setShowSave] = useState(false);
    const [showDelBtn, setShowDelBtn] = useState(false);





    const [editModal, setEditModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");




    // Add Clinician
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [gender, setGender] = useState("");
        const [dob, setDob] = useState();
        const [email, setEmail] = useState("");
        const [clinicianApp, setClinicianApp] = useState("");

        const [address, setAddress] = useState("");
        const [town, setTown] = useState("");
        const [postCode, setPostCode] = useState("");
        const [country, setCountry] = useState("");
        const [phone, setPhoneNo] = useState("");


        // To add New Clinician

        const toAddAdmin = async (e) => {
              e.preventDefault();
                    let postcode =postCode;
                    let eApp =clinicianApp;
                    let eAddData = {
                      firstName:firstName,
                      lastName:lastName,gender:parseInt(gender),dateOfBirth:dob,email:email,
                      app:"modus",address:address,town:town,postcode:postcode,
                      country:country,phone:phone
                    }
                    try {

                      // setIsLoading(true);
                      // e.preventDefault();
                      let result = await dispatch(addAdmin(eAddData));
                      ////("Result",result);
                      // setIsLoading(false);
                      // ShowToast(result.data.message,"success");
                      setAddModal(false);
                      fetchAdmins();



                    } catch (err) {
                      ////(err.response)

                      // ShowToast(err.response.data.message,"error");
                      // setIsLoading(false);
                    }


                    return true;


                  };


    useEffect(() => {
      // // console.log("MODUS 2", modus2);
      // // console.log("prevApp", prevApp);

          fetchAdmins();
      }, [props.role]);


      useEffect(() => {
        // console.log("MODUS 2", modus2);
        // console.log("prevApp", prevApp);

            fetchAdmins();
        }, [modus2]);
      const fetchAdmins = async () => {
      const result = await dispatch(getAdmins(1,"modus"));
      // console.log("Result :", result);
      if(result === false){
        setPlaceHolder(true);
      }

        };


        const saveId=(id)=>{
          // dispatch(fetchClinicianData(id));
          document.getElementById("adminTab").click();
          //history("/admin/viewClinician");
          // dispatch(props.setEData(true))
        }

        const checkClientEmail = async(val) => {
          // console.log("val", val);
          if(val!==""){
          let res = await dispatch(checkEmail(val));

          if(res.status && res.status === 200) {
            // console.log("Res in Comp", res);
            setIdAvail(true);
          } else{
            setIdAvail(false);

          }

        }
        }
        // const checkClientEmail = async(val) => {
        //   console.log("val", val);
        //   if(val!==""){
        //   let res = await dispatch(checkEmail(val));
        //
        //   if(res.status && res.status === 200) {
        //     console.log("Res in Comp", res);
        //     setIdAvail(true);
        //     setIdMessage(res.data.message);
        //   } else{
        //     console.log("Res in Comp else", res.response.data.message);
        //
        //     setIdAvail(false);
        //     setIdMessage(res.response.data.message);
        //   }
        //
        // }
        // }


        const implementChecks = (val) => {
          if(val.length>0){
            setIdAvail(null);
          }
        }
        const deletingAdmin=async()=>{
        const res =  await dispatch(removeAdmin(toDelete));
        if(res===true){
          fetchAdmins();
          setDelModal(false);

        }
        }

        const suspendingUser=async()=>{
        const res =  await dispatch(suspendUser(toSuspend));
        if(res===true){
          setSusModal(false);
          fetchAdmins();

        }
        }

        const activatingUser=async()=>{
        const res =  await dispatch(activateUser(toActivate));
        if(res===true){
          setActModal(false);
          fetchAdmins();

        }
        }

          const assignRole = async () => {
    let dispatchAction;

    switch (selectedRole) {
      case "engineer":
        dispatchAction = makingEngineer(toEdit);
        break;
      case "admin":
        dispatchAction = makingAdmin(toEdit);
        break;
      case "patient":
        dispatchAction = makingPatient(toEdit);
        break;
      case "engineerAdmin":
        dispatchAction = makingEngineerAdmin(toEdit);
        break;
      case "clinicalAdmin":
        dispatchAction = makingClinicalAdmin(toEdit);
        break;

      default:
        // Handle other roles if needed
        break;
    }

    if (dispatchAction) {
      dispatch(dispatchAction);
    }

    setEditModal(false);
  };

          const handleRole =(event)=>{
            setSelectedRole(event.target.value);
            // console.log("event.target.value", event.target.value);
            setShowSave(true);

          }

          const removingRole = async () => {
  let dispatchAction;
  let roleCode;

  switch (selectedRole) {
    case "engineer":
      dispatchAction = removeRole(toRoleRemove, 3);
      break;
    case "admin":
      dispatchAction = removeRole(toRoleRemove, 1);
      break;
    case "clinicalAdmin":
      dispatchAction = removeRole(toRoleRemove, 6);
      break;
    case "engineerAdmin":
      dispatchAction = removeRole(toRoleRemove, 5);
      break;
    case "patient":
      dispatchAction = removeRole(toRoleRemove, 4);
      break;
    default:
      // Handle other roles if needed
      break;
  }

  if (dispatchAction) {
    dispatch(dispatchAction);
    setRemoveRoleModal(false);
    setShowSave(false);
    fetchAdmins();
  }
};

            const handleRemoveRole =(event)=>{
              setSelectedRole(event.target.value);
              // console.log("event.target.value", event.target.value);
              setShowSave(true);

            }

      const chekEmailForDelBtn =(e)=>{
        if(e.target.value === instance.email){
          // console.log("YES");
          setShowDelBtn(true);
        } else {
          setShowDelBtn(false);

        }

      }
      const setMaxDate =()=>{
        let datePickerId = document.getElementById("datePickerId");
        datePickerId.max = new Date().toISOString().split("T")[0];
      }
    return (
        <div className="container-fluid admin-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row p-2 px-3">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12 col-md-6">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Admin's Details</h1>
                            </div>
                            <div className="col-12 col-md-6 text-right">
                            <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true); setMaxDate();}}>Add Admin</button>

                            </div>
                            <div className="col-12">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>DOB</th>

                                <th>Email</th>
                                <th>Joined At</th>
                                <th>Status</th>

                                <th>Country</th>
                                <th>City</th>
                                <th>Phone</th>


                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>

                              { list !== null && list.length > 0 && list.map((row, index)=>(
                              <tr key={index}>
                                <td>{row.firstName}</td>
                                <td>{row.lastName}</td>
                                <td>{moment(row.dateOfBirth).format("D MMM YYYY")}</td>
                                <td>{row.email}</td>
                                <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                                <td>{row.status}</td>
                                <td>{row.country}</td>
                                <td>{row.city}</td>
                                <td>{row.phone}</td>
                                <td className="text-left">
                                {row.role.length !== 5 ?

                                  <button
                                      type="button"
                                      className="mr-2"

                                      onClick={(event)=>{
                                        setInstance(row)
                                        setEditModal(true);
                                        setToEdit(row._id);

                                      }}
                                  >
                                    <span className="">

                                    <i className="fas fa-edit text-primary"></i>
                                    </span>
                                  </button>
                                :null}

                                  <button
                                      type="button"
                                      className=" mr-2"
                                      onClick={()=>{
                                        setToDelete(`${row._id}`);
                                        setInstance(row)
                                        setDelModal(true);
                                    }}
                                  >
                                                      <span className="">
                                                      <i className="fas fa-trash-alt text-danger"></i>
                                                      </span>
                                  </button>
                                  {row.status === "active"?
                                  <button
                                      type="button"
                                      className=" mr-2"
                                      onClick={()=>{
                                        setToSuspend(`${row._id}`);
                                        setSusModal(true);
                                    }}
                                  >
                                                      <span className="">
                                                      <i className="fa fa-ban" aria-hidden="true"></i>

                                                      </span>
                                  </button>:null}
                                  {row.status!== "active"?
                                  <button
                                      type="button"
                                      className=" mr-2"
                                      onClick={()=>{
                                        setToActivate(`${row._id}`);
                                        setActModal(true);
                                    }}
                                  >
                                                      <span className="">
                                                      <i className="fa fa-lightbulb" aria-hidden="true"></i>

                                                      </span>
                                  </button>:null}

                                  <button
                                      type="button"
                                      className=" mr-2"
                                      onClick={()=>{
                                        setInstance(row)
                                        setRemoveRoleModal(true);
                                        setToRoleRemove(row._id);
                                    }}
                                  >
                                                      <span className="">
                                                      <i className="fa fa-users" aria-hidden="true"></i>


                                                      </span>
                                  </button>

                                </td>



                              </tr>
                            ))}

                              </tbody>
                            </table>
                            {list === null || list.length === 0? <h1 className="text-center">Admins Not Found!</h1>:null}
                            </div>


                            <div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false); setShowDelBtn(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this account? <br></br> Type "<strong>{instance.email}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      id="title"
                                      onChange={(e)=>{chekEmailForDelBtn(e)}}
                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false); setShowDelBtn(false)}}>Cancel</button>

                                        {showDelBtn?<button type="button" className="btn btn-danger" onClick={()=>deletingAdmin()} >Delete</button>:null}

                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className={susModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-trash-alt text-danger"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                        <button type="button" className="close" onClick={()=>{setSusModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <p>Do you really want to suspend this records?</p>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{setSusModal(false)}}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={()=>{suspendingUser()}}>Suspend</button>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className={actModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-ban text-danger"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                          <button type="button" className="close" onClick={()=>{setActModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                          <p>Do you really want to Activate this user?</p>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          <button type="button" className="btn btn-secondary" onClick={()=>{setActModal(false)}}>Cancel</button>
                                          <button type="button" className="btn btn-danger" onClick={()=>{activatingUser()}} >Activate</button>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                <div
                                    className={`modal big_modal_dialog ${
                                        addModal? "d-block modal-fading" : " d-none"
                                    }`}
                                    tabIndex="-1"
                                    data-backdrop="static"
                                    data-keyboard="false"

                                    role="dialog"

                                    aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="addHospitalModalTitle">
                                          Add Admin
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={()=>setAddModal(false)}
                                        >
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <form className="pl-3 pr-3" onSubmit={toAddAdmin}>
                                        <div className="container my-4 ">
                                        <div className="row justify-content-center align-items-center mb-3 text-white">
                                          <div className="col-md-2 registration-input-labels"><p className="text-black">Email</p></div>
                                          <div className="col-md-10">
                                            <input
                                              type="email"
                                              value={email}
                                              onChange={(e) => {setEmail(e.target.value); implementChecks(e.target.value)}}
                                              onBlur={(e)=> checkClientEmail(e.target.value)}
                                              className={idAvail ===true ?"form-control border-success": idAvail=== false ?"form-control border-danger":"form-control"}
                                              required
                                              style={{color:"black"}}
                                            />
                                            {idAvail=== true? <span className="text-success ml-3">Available</span>:idAvail===false?<span className="text-danger ml-3">Exist</span>:null}
                                          </div>
                                        </div>
                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">First Name</p></div>
                                            <div className="col-md-4">
                                              <input
                                               value={firstName}
                                               onChange={(e) => setFirstName(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Last Name</p></div>
                                            <div className="col-md-4">
                                              <input
                                              value={lastName}
                                              onChange={(e) => setLastName(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Gender</p></div>
                                            <div className="col-md-4">
                                              <div className="styled-select">
                                                <select
                                                  value={gender}
                                                  onChange={(e) => setGender(e.target.value)}
                                                  placeholder="Select a Blood Group"
                                                  className="form-control "
                                                  style={{color:"black"}}
                                                >
                                                <option value="Select">Select</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                                <option value="4">Prefer Not To Share</option>
                                                </select>
                                                <i className="fa fa-chevron-down"></i>
                                              </div>
                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">DOB</p></div>
                                            <div className="col-md-4">
                                              <input
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                type="date"
                                                className="registration-datePicker"

                                                required
                                                style={{color:"black"}}
                                                id="datePickerId"
                                              />
                                            </div>
                                          </div>
                                          {/*<div className="row justify-content-center">
                                          <div className="col-md-2 registration-input-labels"><p className="text-black">App</p></div>

                                            <div className="col-md-10 col-10 ">
                                              <div className="form-group">
                                                <div className="styled-select">
                                                  <select
                                                    name="relation"
                                                    placeholder="Select a Role"
                                                    className="form-control input-md msg"

                                                    onChange={(e) =>{

                                                      setClinicianApp(e.target.value);
                                                    }
                                                    }
                                                    required
                                                    style={{color:"black"}}
                                                  >
                                                    <option value="">Select</option>
                                                    <option value="eostre">Eostre</option>
                                                    <option value="modus">Modus</option>


                                                  </select>

                                                </div>
                                              </div>
                                            </div>
                                          </div>*/}



                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Phone No</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={phone}
                                              onChange={(e) => setPhoneNo(e.target.value)}
                                                type="tel"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Address</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={address}
                                              onChange={(e) => setAddress(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">Town</p></div>
                                            <div className="col-md-10">
                                              <input
                                               value={town}
                                               onChange={(e) => setTown(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Postcode</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={postCode}
                                              onChange={(e) => setPostCode(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels "><p className="text-black">Country</p></div>
                                            <div className="col-md-10">
                                            <div className="styled-select">
                                                <select
                                                   value={country}
                                                   onChange={(e) => setCountry(e.target.value)}
                                                  placeholder="Select a Blood Group"
                                                  className="form-control "
                                                  style={{color:"black"}}

                                                >
                                                  <option value="Select">Select Country</option>
                                                  {countryList.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                          ))}
                                                </select>
                                                <i className="fa fa-chevron-down"></i>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                        <button className="btn btn-primary" type="submit">Add Admin </button>

                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>

                                <div className={editModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-edit text-primary"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Change Role</h4>
                                          <button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                        <label htmlFor="roles">Choose a New Role &nbsp; </label>

                                        <select name="roles" id="roles" onChange={(e)=>{handleRole(e)}}>
                                          <option value=""></option>
                                          {/*<option value="clinician">Clinician</option>*/}

                                          {/*instance!== "" && instance!== null && instance.role.includes(1) === false?

                                          <option value="admin">Admin</option>
                                          :null*/}

                                          {instance!== "" && instance!== null && instance.role.includes(3) === false?

                                          <option value="engineer">Engineer</option>
                                          :null}

                                          {instance!== "" && instance!== null && instance.role.includes(4) === false?

                                          <option value="patient">Patient</option>
                                          :null}
                                          {/*instance!== "" && instance!== null && instance.role.includes(5) === false?

                                          <option value="engineerAdmin">Engineer Admin</option>
                                          :null*/}
                                          {/*instance!== "" && instance!== null && instance.role.includes(6) === false?

                                          <option value="clinicalAdmin">Clinical Admin</option>
                                          :null*/}

                                        </select>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{assignRole()}}>Save</button>: null}

                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className={removeRoleModal? "modal d-block fading" : "modal d-none"}>
                                      <div className="modal-dialog modal-dialog-centered modal-confirm">
                                        <div className="modal-content">
                                          <div className="modal-header flex-column">
                                            <div className="icon-box">
                                              <i className="fas fa-edit text-primary"></i>
                                            </div>
                                            <h4 className="modal-heading w-100 text-black">Change Roles</h4>
                                            <button type="button" className="close" onClick={()=>{setRemoveRoleModal(false)}} >&times;</button>
                                          </div>
                                          <div className="modal-body">
                                          <label htmlFor="roles">Select a Role to be removed &nbsp; </label>

                                          <select name="roles" id="roles" onChange={(e)=>{handleRemoveRole(e)}}>
                                            <option value=""></option>
                                            {/*<option value="clinician">Clinician</option>*/}

                                            {instance!== "" && instance!== null && instance.role.includes(1) === true?

                                            <option value="admin">Admin</option>
                                            :null}

                                            {instance!== "" && instance!== null && instance.role.includes(3) === true?

                                            <option value="engineer">Engineer</option>
                                            :null}

                                            {instance!== "" && instance!== null && instance.role.includes(4) === true?

                                            <option value="patient">Patient</option>
                                            :null}
                                            {instance!== "" && instance!== null && instance.role.includes(5) === true?

                                            <option value="engineerAdmin">Engineer Admin</option>
                                            :null}
                                            {instance!== "" && instance!== null && instance.role.includes(6) === true?

                                            <option value="clinicalAdmin">Clinical Admin</option>
                                            :null}

                                          </select>
                                          </div>
                                          <div className="modal-footer justify-content-center">
                                            {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{removingRole()}}>Remove</button>: null}

                                          </div>
                                        </div>
                                      </div>

                                    </div>
                        </div>

                    </div>
                </div>
            {/*</div>*/}
        </div>
    );
};

export default Admins;
