import React, {useEffect, useState} from "react";
import {useDispatch, useSelector,shallowEqual} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

const SensorUsageReport = (props) => {
  console.log("Props :", props.sensorDetails);
  const history = useNavigate();
  const loc = useLocation();

  const[details, setDetails]=useState({});
  const totalDays =props.sensorDetails && props.sensorDetails.totalDays ? props.sensorDetails.totalDays: 0;
  const id = props.sensorDetails && props.sensorDetails._id ?  props.sensorDetails._id:0;
  const getRemainDays = (addon) =>{
    console.log("ADDON", addon);

    let a = moment(addon);
    let c = moment(addon).add(14, 'days');
    let b  = moment(new Date);
    let remain = c.diff(b, 'days');
    console.log("REMAIN :", remain);
     return remain;
  }
  const remainingDays = getRemainDays(props.sensorDetails && props.sensorDetails.addedOn? props.sensorDetails.addedOn: new Date());

  // const {sensorDetails} = useSelector((state) => state.bloodGlucose);
  const {sensorDetails} = useSelector((state) => state.bloodGlucose);


  return (
    <div className='h-100 d-flex flex-column justify-content-between'>

    <div className="chart-heading mt-3 loginNew-HCP-panel">
    <span className="bg-primary px-2 btn py-2 font-weight-bold text-white"
      style={{border: "none",borderRadius: "0" ,cursor:"unset"}}>
        Sensor Usage
      </span>
    </div>
    {/* <div>
      <i class="fas fa-ellipsis-h"></i>
    </div> */}



  <div className="d-flex flex-column justify-content-between px-3 mt-3 mb-4">
    <div className= {loc.pathname!== "/export-data"?"text-left border-bottom" :"text-center border-bottom"}>
      {/*<small className='font-size-normal font-weight-bold'>Days with CGM Data</small><br/>*/}
      <small className='font-size-normal font-weight-bold'>Device ID</small><br/>

      <small className="font-size2-normal font-weight-bold d-inline mt-n4 font-blue">bfcd4ee6-71de-4fec-9fd2-843ebacf41e8</small><br/>
      <small className='font-size-normal font-weight-bold'>Total Days</small><br/>

      <small className="font-size1 font-weight-bold font-blue">{totalDays}</small><br/>
    </div>

    <div className= {loc.pathname!== "/export-data"?"text-left border-bottom" :"text-center border-bottom"}>
      {/*<small className='font-size-normal font-weight-bold'>Avg. Calibrations Per Day</small><br/>*/}
      <small className='font-size-normal font-weight-bold'>Remaining Days(Expiry)</small><br/>

      <small className="font-size2-half font-weight-bold d-inline mt-n4 font-blue">{remainingDays && (remainingDays === 0 || (remainingDays > 0 && remainingDays < 15))? remainingDays : 0 }</small><br/>

    </div>


  </div>
  </div>

  );

};

export default SensorUsageReport;
