import * as actions from "../actionTypes";
import {
  API_BASE_URL,
  loginURL,
  forgotPasswordURL,
  createUserURL,
  resetPasswordURL,
  resendVerificationEmailURL,
  accountActicationUrl,
  deleteAccountURL
} from "../../utils/urls";
import http from "../../utils/http-service";
import { fetchUser } from "./user";
import {decrypt} from "../../utils/encryption";
import {updateUserRole} from "./user";
import ShowToast from "../../utils/show-toast";



const loginStart = () => {
  return { type: actions.LOGIN_START };
};

const loginFailed = () => {
  return { type: actions.LOGIN_FAILED };
};

export const loginSuccess = (payload) => {
  return { type: actions.LOGIN_SUCCESS, payload };
};

export const login = (data) => async (dispatch) => {

  try {
    dispatch(loginStart());
    const { email, password } = data;

    // let loginData = {
    //   email,
    //   password
    //   // app:"web"
    // };
    // loginData = JSON.stringify(loginData);
    // loginData = encrypt(loginData);
    const result = await http.post(`${API_BASE_URL}${loginURL}`, {
      email,
      password
    });

    if (result.status === 200) {

      let {
        data: { data },
      } = result;
      // data = decrypt(data);
      // data = JSON.parse(data);

      localStorage.setItem("EostreAuthToken", data.token);
      dispatch(loginSuccess(data));
      if(localStorage.getItem("Apple")){
        let user = localStorage.getItem("Apple");
        user = decrypt(user);
        // user = JSON.parse(user);
        console.log("USER IN ROUTE :", user);
        dispatch(updateUserRole(user));
      } else {
        dispatch(fetchUser());

      }
    }

    return result;
  } catch (error) {

    dispatch(loginFailed());
    throw error;
  }
};

export const logout = () => {
  console.log("window.location.pathname in logout", window.location.pathname);
  if( window.location.pathname !== "/auth/login" && window.location.pathname !== "/dashboard" && window.location.pathname !== "/export-data" && window.location.pathname !== "/auth/deleteUser"){

    localStorage.setItem("pathname", window.location.pathname);

  } else if(window.location.pathname === "/auth/deleteUser"){
    let comPath = window.location.pathname;
    let access_token = new URLSearchParams(window.location.search).get('token');
    comPath  = comPath+"?token="+access_token;
    console.log("comPath", comPath);
    localStorage.setItem("pathname", comPath);
  }

  localStorage.removeItem("EostreAuthToken");
  localStorage.removeItem("Apple");

  return {
    type: actions.USER_LOGGED_OUT,
  };
};

const registerStart = () => {
  return { type: actions.REGISTER_START };
};

const registerFailed = () => {
  return { type: actions.REGISTER_FAILED };
};

const registerSuccess = (payload) => {
  return { type: actions.REGISTER_SUCCESS, payload };
};

export const register = (data) => async (dispatch) => {
  try {
    dispatch(registerStart());
    // let registerData={...data}

    // registerData=JSON.stringify(registerData)
    // registerData = encrypt(registerData);
    const result = await http.post(`${API_BASE_URL}${createUserURL}`, data);

    if (result.status === 200) {
      const {
        data: { data },
      } = result;


      delete data.token;
      dispatch(registerSuccess(data));
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(registerFailed());
    throw error;
  }
};

const forgotPasswordStart = () => {
  return { type: actions.FORGOT_PASSWORD_START };
};

const forgotPasswordFailed = () => {
  return { type: actions.FORGOT_PASSWORD_FAILED };
};

const forgotPasswordSuccess = (payload) => {
  return { type: actions.FORGOT_PASSWORD_SUCCESS, payload };
};

export const forgotPassword = (data) => async (dispatch) => {
  dispatch(forgotPasswordStart());

  try {
    // let forgetData=data
    // forgetData=JSON.stringify(forgetData)
    // forgetData=encrypt(forgetData)
    const result = await http.post(`${API_BASE_URL}${forgotPasswordURL}`, data);
    dispatch(forgotPasswordSuccess(result));

    return result;
  } catch (error) {

    dispatch(forgotPasswordFailed());
    throw error;
  }
};

const resetPasswordStart = () => {
  return { type: actions.RESET_PASSWORD_START };
};

const resetPasswordFailed = () => {
  return { type: actions.RESET_PASSWORD_FAILED };
};

const resetPasswordSuccess = (payload) => {
  return { type: actions.RESET_PASSWORD_SUCCESS, payload };
};

export const resetPassword = (data) => async (dispatch) => {
  dispatch(resetPasswordStart());

  try {
    // let resetData=data
    // resetData=JSON.stringify(resetData)
    // resetData=encrypt(resetData)

    const result = await http.post(`${API_BASE_URL}${resetPasswordURL}`, data);
    dispatch(resetPasswordSuccess(result));


    return result;
  } catch (error) {
    dispatch(resetPasswordFailed());
    throw error;
  }
};

const resendVerificationEmailStart = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_START };
};

const resendVerificationEmailFailed = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_FAILED };
};

const resendVerificationEmailSuccess = (payload) => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_SUCCESS, payload };
};

export const resendVerificationEmail = (data) => async (dispatch) => {
  try {
    dispatch(resendVerificationEmailStart());
    // let resendEmailData=data
    // resendEmailData=JSON.stringify(resendEmailData)
    // resendEmailData=encrypt(resendEmailData)
    const result = await http.post(
      `${API_BASE_URL}${resendVerificationEmailURL}`,
      data
    );

    if (result.status === 200) {
      const {
        data: { data },
      } = result;
      // data=decrypt(data);
      // data=JSON.parse(data);
      dispatch(resendVerificationEmailSuccess(data));
    }

    return result;
  } catch (error) {
    dispatch(resendVerificationEmailFailed());
    throw error;
  }
};
export const accountActivation = (token) => async (dispatch) => {
  let result;
  try {

    // let resetData=data
    // resetData=JSON.stringify(resetData)
    // resetData=encrypt(resetData)

    result = await http.get(`${API_BASE_URL}${accountActicationUrl}?token=${token}`);


    // ShowToast(result.data.message,"success");
    return result;

  } catch (error) {

    dispatch(resetPasswordFailed());
    if( error.response.status===409){
      // ShowToast(error.response.data.message,"success");
    }
    else{
      // ShowToast(error.response.data.message,"error");
    }
    throw error;
    //  return error.response;
  }
};

export const deleteAccountReq = () => async (dispatch) => {

  try {

    const  result = await http.get(`${API_BASE_URL}${deleteAccountURL}`);

    // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};

export const deleteAccountConfirm = (token) => async (dispatch) => {

  try {

    const  result = await http.delete(`${API_BASE_URL}/user/verifyRemoveUser?token=${token}`);

    // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};
