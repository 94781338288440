import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl, getSensorDetails, carbsUrl, insulinUrl, activityUrl } from "../../utils/urls";
import {
  FETCH_BG_START,
  FETCH_BG_FAILED,
  FETCH_BG_SUCCESS,
  FETCH_SENSOR_START,
  FETCH_SENSOR_FAILED,
  FETCH_SENSOR_SUCCESS,
  FETCH_CARBS_START,
  FETCH_CARBS_FAILED,
  FETCH_CARBS_SUCCESS,
  FETCH_INSULIN_START,
  FETCH_INSULIN_FAILED,
  FETCH_INSULIN_SUCCESS,
  FETCH_ACTIVITY_START,
  FETCH_ACTIVITY_FAILED,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_CGM_REPO_SUCCESS,
  FETCH_HISTORY_CGM_RECORD_SUCCESS,
  SAVE_MBG_SUCCESS,
  SAVE_SDBG_SUCCESS,
  SAVE_CV_SUCCESS,
  SAVE_LAGE_SUCCESS,
  SAVE_MAGE_SUCCESS,
  SAVE_JINDEX_SUCCESS,
  SAVE_OGP_SUCCESS,
  SAVE_MVALUE_SUCCESS,
  SAVE_IQR_SUCCESS,
  SAVE_AAC_SUCCESS,
  SAVE_AUC_SUCCESS,
  SAVE_HBGI_SUCCESS,
  SAVE_ADRR_SUCCESS,






} from "../actionTypes";
import moment from "moment";


// import {encrypt, decrypt} from "../../../utils/encryption";

export const fetchBgData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_BG_START,
  });
try {
  // // console.log("Data",data);
    let bg;
    let res;
    let dayDif;
    let type = data && data.type === 4 ? data.type : 1;
    console.log("TYPE", type);
    if(data.time){
      bg = data.time;
      dayDif = data.time;
      // bg = encrypt(bg);

      // let type = 1;
      // type = JSON.stringify(type);
      // type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?time=${bg}`);
     console.log("RES", res);
    } else if(data.start_date) {
      console.log("in ELSE Start Date", data.start_date);
      let bg_start = moment(data.start_date);
      // bg_start = encrypt(bg_start);
      let bg_end = moment(data.end_date);
      dayDif = moment.duration(bg_end.diff(bg_start)).asDays();
console.log("Day Dif", dayDif);
      // bg_end = encrypt(bg_end);

      // let type = 1;
      // type = JSON.stringify(type);
      // type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?start_date=${bg_start}&end_date=${bg_end}`,);
     console.log("RES range", res);

}
let gift = res.data.data;
console.log("gift CGM", gift);
// gift = decrypt(gift);
// gift = JSON.parse(gift);

  // updateUnit(unit, res.data.data)
  if(data.type === 4){
console.log("gift", gift);
    dispatch({type:FETCH_CGM_REPO_SUCCESS, payLoad:gift});
    if(gift.overlay && gift.overlay.length>0){
      let len = gift.overlay.length;
      let mbg;
      let cv;
      let lage;
      let jIndex;
      let bgVal =[];
      let val=0;
      let mage;
//       function calculateAverageBloodGlucose(readings) {
//   let sum = 0;
//   for (const reading of readings) {
//     sum += reading.value;
//   }
//   return sum / readings.length;
// }
//
// const averageBloodGlucose = calculateAverageBloodGlucose(gift.overlay);
// console.log("Average Blood Glucose:", averageBloodGlucose.toFixed(2));
      gift.overlay.forEach((x)=>{
        let bloodGlucoseVal = x.value;
        // bloodGlucoseVal = bloodGlucoseVal.toFixed(2);
        bgVal.push(bloodGlucoseVal);
        val = val + x.value;
      });
      mbg = val/ gift.overlay.length;
      mbg = mbg.toFixed(2);
      dispatch({ type: SAVE_MBG_SUCCESS, payLoad: mbg });
// HbA1c calculations Start

// const eHbA1c = (10.929 * mbg) - 38.46;;
// console.log("eHbA1c (mmol/mol):", eHbA1c.toFixed(2));
// const eHbA1cPercent = ((eHbA1c + 46.7) / 28.7)* 100;
// console.log("eHbA1cPercent", eHbA1cPercent);
//
//
//
// function calculateEHbA1cMmol(averageBloodGlucoseMmol) {
//   return (averageBloodGlucoseMmol + 46.7) / 28.7;
// }
//
// function calculateEHbA1cPercentageFromMmol(eHbA1cMmol) {
//   const eHbA1cPercentage = (eHbA1cMmol * 10) + 2;
//   return eHbA1cPercentage;
// }
//
// const averageBloodGlucoseMmol = 8.96;
// const eHbA1cMmol = calculateEHbA1cMmol(averageBloodGlucoseMmol);
// const eHbA1cPercentage = calculateEHbA1cPercentageFromMmol(eHbA1cMmol);
//
// console.log("eHbA1c (mmol/mol):", eHbA1cMmol.toFixed(2));
// console.log("eHbA1c (%):", eHbA1cPercentage.toFixed(2));
// HbA1c calculations end

// MAGE calculation start
const calculateMAGE = (bloodGlucoseReadings)=> {
  if (bloodGlucoseReadings.length < 3) {
    return null; // MAGE requires at least 3 readings
  }

  let sumDifferences = 0;

  for (let i = 1; i < bloodGlucoseReadings.length - 1; i++) {
    const [prevReading, currentReading, nextReading] = [
      bloodGlucoseReadings[i - 1].value,
      bloodGlucoseReadings[i].value,
      bloodGlucoseReadings[i + 1].value,
    ];

    const peak = Math.max(prevReading, currentReading, nextReading);
    const nadir = Math.min(prevReading, currentReading, nextReading);

    sumDifferences += peak - nadir;
  }

  const mage = sumDifferences / (bloodGlucoseReadings.length - 2);
  return mage;
}

 mage = calculateMAGE(gift.overlay);
console.log("MAGE :", mage);
dispatch({ type: SAVE_MAGE_SUCCESS, payLoad: mage.toFixed(2) });

// M-value

function calculateMValue(bloodGlucoseReadings, referenceValue) {
  // Calculate the logarithmic transformation for each reading
  const logTransformedValues = bloodGlucoseReadings.map(reading => Math.log(reading));


  // Calculate the average of the logarithmic values
  const averageLogValue = logTransformedValues.reduce((sum, value) => sum + value, 0) / logTransformedValues.length;

  // Calculate the logarithmic transformation of the reference value
  const logReferenceValue = Math.log(referenceValue);

  // Calculate and return the M-Value
  const mValue = averageLogValue - logReferenceValue;
  return mValue;
}

const referenceValue = 18; // Reference blood glucose value

// Extract blood glucose values from the array
const readings = gift.overlay.map(reading => reading.value);
console.log("READINGS :", readings);
//
// // Calculate and display the M-Value
// const mValue = calculateMValue(readings, referenceValue);
// console.log("M-Value:", mValue.toFixed(2));







// Calculate the sum of absolute differences of logarithmic transformations
const sumOfDifferences = readings.reduce((sum, value) => sum + Math.abs(Math.log(value) - Math.log(referenceValue)), 0);

// Calculate the M-Value
const mValue = sumOfDifferences / readings.length;

// console.log("M-Value:", mValue.toFixed(2));
dispatch({ type: SAVE_MVALUE_SUCCESS, payLoad: mValue.toFixed(2) });
// M-vlue end



// IQR Calcuations start
const calculateIQR=(data)=> {
  // Extract blood glucose values from the dataset
  const bloodGlucoseValues = data.map(entry => entry.value);

  // Sort the values in ascending order
  bloodGlucoseValues.sort((a, b) => a - b);

  // Calculate the first quartile (Q1) and third quartile (Q3)
  const n = bloodGlucoseValues.length;
  const q1Index = Math.floor(n * 0.25);
  const q3Index = Math.floor(n * 0.75);
  const q1 = bloodGlucoseValues[q1Index];
  const q3 = bloodGlucoseValues[q3Index];

  // Calculate the Interquartile Range (IQR)
  const iqr = q3 - q1;

  return iqr;
}

// Example dataset


const iqr = calculateIQR(gift.overlay);
console.log("Interquartile Range (mmol/L):", iqr.toFixed(2));

dispatch({ type: SAVE_IQR_SUCCESS, payLoad: iqr.toFixed(2) });

// IQR Calculaitons End


// Area Above Curve (AAC) start
const calculateAAC=(data, threshold)=> {
  let aac = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].value > threshold) {
      aac += data[i].value - threshold;
    }
  }
  return aac;
}



const threshold = 5.6; // mmol/L

const aac = calculateAAC(gift.overlay, threshold);
console.log("Area Above the Curve (AAC) for", threshold, "mmol/L:", aac.toFixed(2));

dispatch({ type: SAVE_AAC_SUCCESS, payLoad: aac.toFixed(2) });

// Area Above Curve (AAC) end

// Area Under the Curve start
const calculateAUC=(data, threshold)=> {
  let auc = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].value <= threshold) {
      auc += threshold - data[i].value;
    }
  }
  return auc;
}



const thresholdAuc = 5.6; // mmol/L

const auc = calculateAUC(gift.overlay, thresholdAuc);
console.log("Area Under the Curve (AUC) for", thresholdAuc, "mmol/L:", auc.toFixed(2));
dispatch({ type: SAVE_AUC_SUCCESS, payLoad: auc.toFixed(2) });
// Area Under the Curve end


// High Blood Glucose Index With Threshold start
const thresholdHbgi = 10.1; // Threshold value for high blood glucose (mmol/L)

// Calculate HBGI for each data point
const hbgiValues = gift.overlay.map(dataPoint => {
  const bg = dataPoint.value;
  const hbgi = Math.max(0, bg - thresholdHbgi);
  return hbgi;
});

// Calculate the average HBGI
const averageHbgi = hbgiValues.reduce((sum, hbgi) => sum + hbgi, 0) / hbgiValues.length;

console.log("HBGI values:", hbgiValues);
console.log("Average HBGI:", averageHbgi);
dispatch({ type: SAVE_HBGI_SUCCESS, payLoad: averageHbgi.toFixed(2) });
// High Blood Glucose Index With Threshold End

//Average Daily Risk Range Start
const hypoglycemiaThreshold = 3.9; // 70 mg/dL or 3.9 mmol/L
const hyperglycemiaThreshold = 4.2; // 180 mg/dL or 10 mmol/L

let hypoglycemiaSum = 0;
let hypoglycemiaCount = 0;
let hyperglycemiaSum = 0;
let hyperglycemiaCount = 0;

for (const reading of gift.overlay) {
  if (reading.value < hypoglycemiaThreshold) {
    hypoglycemiaSum += hypoglycemiaThreshold - reading.value;
    hypoglycemiaCount++;
  }
  if (reading.value > hyperglycemiaThreshold) {
    hyperglycemiaSum += reading.value - hyperglycemiaThreshold;
    hyperglycemiaCount++;
  }
}

const avgHypoglycemiaADRR = hypoglycemiaCount > 0 ? hypoglycemiaSum / hypoglycemiaCount : 0;
const avgHyperglycemiaADRR = hyperglycemiaCount > 0 ? hyperglycemiaSum / hyperglycemiaCount : 0;

const overallADRR = avgHypoglycemiaADRR + avgHyperglycemiaADRR;

console.log("Average Hypoglycemia ADRR:", avgHypoglycemiaADRR);
console.log("Average Hyperglycemia ADRR:", avgHyperglycemiaADRR);
console.log("Overall ADRR:", overallADRR);
dispatch({ type: SAVE_ADRR_SUCCESS, payLoad: overallADRR.toFixed(2) });
//Average Daily Risk Range End
      let sd = [];
      let sdbg;
      let val2 = 0;

      gift.overlay.forEach((x)=>{
        let unit = x.value - mbg;
        unit  = Math.pow(unit, 2);
        sd.push(unit);
      });
      sd.forEach((item, i) => {
        val2 = val2 + item;
      });
      val2 = val2/len;
      sdbg = Math.sqrt(val2);
      sdbg = sdbg.toFixed(2);

      dispatch({ type: SAVE_SDBG_SUCCESS, payLoad: sdbg });
      cv = sdbg/mbg;
      console.log("CV :", cv);
      cv = cv * 100;
      cv = cv.toFixed(2);
      dispatch({ type: SAVE_CV_SUCCESS, payLoad: cv });
      let maxBgVal = Math.max(...bgVal);
      let minBgVal = Math.min(...bgVal);
      lage = maxBgVal - minBgVal;
      lage = lage.toFixed(2);
      dispatch({ type: SAVE_LAGE_SUCCESS, payLoad: lage });
      let mbgsdbg = parseInt(mbg)+ parseInt(sdbg);
      // console.log(" mbsdbg", mbgsdbg);
      jIndex = 0.324 * Math.pow(mbgsdbg, 2);
      jIndex = jIndex.toFixed(2);
      dispatch({ type: SAVE_JINDEX_SUCCESS, payLoad: jIndex });
      dispatch(fetchCgmDataForHistory(gift.overlay, dayDif));
      let ogpReading =  makeAGPReadings(gift.overlay);
      console.log("ogpReading", ogpReading);
      dispatch({ type: SAVE_OGP_SUCCESS, payLoad: ogpReading });

    }

  } else {
    dispatch({ type: FETCH_BG_SUCCESS, payLoad: gift });
  }
  // dispatch({ type: FETCH_BG_SUCCESS, payLoad: gift });
  console.log("GIFT", gift);

  return res;
} catch (error) {
  // // console.log("ERRROR", error);
  dispatch({
    type: FETCH_BG_FAILED,
  });
}
};

export const fetchSensorData = () => async(dispatch) => {
  dispatch({
    type: FETCH_SENSOR_START,
  });
  try {
    // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${getSensorDetails}`);
    let gift = res.data.data;
    // // console.log("gift", gift);
// gift = decrypt(gift);
// gift = JSON.parse(gift);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_SENSOR_SUCCESS, payLoad: gift });
    return res;
  } catch (error) {
    // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_SENSOR_FAILED,
    });
  }
}


export const fetchCarbohydratesData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_CARBS_START,
  });
  try {
    // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${carbsUrl}`);
    let gift = res.data.data;
    console.log("gift", gift);
// gift = decrypt(gift);
// gift = JSON.parse(gift);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_CARBS_SUCCESS, payLoad: gift });
    return res;
  } catch (error) {
    // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_CARBS_FAILED,
    });
  }
}

export const fetchInslinData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_INSULIN_START,
  });
  try {
    // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${insulinUrl}`);
    let gift = res.data.data;
    console.log("gift", gift);
// gift = decrypt(gift);
// gift = JSON.parse(gift);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_INSULIN_SUCCESS, payLoad: gift });
    return res;
  } catch (error) {
    // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_INSULIN_FAILED,
    });
  }
}

export const fetchActivityData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_ACTIVITY_START,
  });
  try {
    // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${activityUrl}`);
    let gift = res.data.data;
    console.log("gift", gift);
// gift = decrypt(gift);
// gift = JSON.parse(gift);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_ACTIVITY_SUCCESS, payLoad: gift });
    return res;
  } catch (error) {
    // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_ACTIVITY_FAILED,
    });
  }
}


const findMinMax=(arr,key)=> {
const datas = arr.map((node) => node[key]);
return {
  min: Math.min(...datas),
  max: Math.max(...datas),
}
}



const fetchCgmDataForHistory = (response, daysDif) => async(dispatch) => {
let dDifference = daysDif.replace(/[^A-Z\d\s]/g, "");



    // let cgm_start = data.timeFrom;
    let cgm_start = findStartDate(daysDif);


    const res = response;
    let weeksArr=[];



        let data = response;
        let firstWeekStartDate = moment(cgm_start);

        // let numberOfWeeks = Math.ceil(daysDif / 1);
        let numberOfWeeks = dDifference;

        let weekStartDate="";
        let weekEndDate="";
        for(let i=0; i<numberOfWeeks; i++){
          if(i === 0){
            weekStartDate= firstWeekStartDate;
            weekEndDate = moment(firstWeekStartDate).add(24, 'hours');
          }
          let week=[];
          if (data && data.length > 0) {
            let weekSD = weekStartDate;
            let weekED = weekEndDate;

            data.forEach((day, index)=> {
            let p1 = moment(weekSD);

            let p2 = moment(day.time);

            let p3 = moment(weekED);


            if (p2.isAfter(p1) && p2.isBefore(p3)){
              // // // console.log("READING TIME", p2);
              let timeUTC = day.time;
              // // console.log("day.time", day.time);
              // let converted = moment(timeUTC).utc();
              // // // console.log("CHECK", moment(converted).tz.guess());

              // const gue =  moment.tz(timeUTC, "UTC");
              const gue =  moment(timeUTC);
              // // console.log("gue", gue);
              let conTime = moment(gue._d).format("HH:mm");
              // // console.log("conTime",conTime);

              let obj = {readingType: day.readingType, time: day.time, glucose: day.value, takenTime:day.takenTime, userPId: day.userPId, _id: day._id}
              week.push(obj);
            }
          })
        } else {
        }
          let obj = {weekStartDate:new Date(weekStartDate).toString(),
            readings:[week]}
          weeksArr.push(obj);
          // weekStartDate= moment(weekEndDate).add(1, 'days');
          // weekEndDate = moment(weekEndDate).add(7, 'days');
          weekStartDate= moment(weekEndDate)
          weekEndDate = moment(weekEndDate).add(24, 'hours');
        };




    // let gift = res.data;

  dispatch({ type: FETCH_HISTORY_CGM_RECORD_SUCCESS, payLoad: weeksArr });
  // console.log("WEEK ARRAY", weeksArr);
  return weeksArr;

};


const findStartDate = (filter) => {


    if(filter === "30days") {
      let start = moment().subtract(30, 'days');


      return new Date(start._d);

    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');


      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');

      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');


      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');


      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');


      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');


      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }


//   const makeAGPReadings= (data)=>{
//   let eachHourData=[];
//   let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
//
//     for(let i =0;i<24; i++){
//       let obj = data.filter((dayInner, index)=>{
//       return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])
//
//     });
//       obj.sort((a, b)=>{return a.value-b.value});
//       eachHourData.push(obj);
//     }
//   return eachHourData;
// }

const makeAGPReadings = (data) => {
  const timeSlots = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));

  const eachHourData = timeSlots.map((slot) => {
    const filteredData = data.filter((item) => {
      const hour = parseInt(moment(item.time).format("HH"));
      return hour === parseInt(slot);
    });

    filteredData.sort((a, b) => a.value - b.value);
    return filteredData;
  });

  return eachHourData;
};
