import React, { useEffect, useState } from "react";

import {Pattern} from "@amcharts/amcharts4/core";
import {useDispatch, useSelector} from "react-redux";

import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";
import OverViewComp from "../Components/Reports/OverViewComp";
import PatternsComp from "../Components/Reports/PatternsComp";
import AverageGlucoseReport from "../Components/Reports/OverviewComps/AverageGlucoseReport";
import CGMReport from "../Components/Reports/OverviewComps/CGMReport";
import BloodGlucoseReport from "../Components/Reports/OverviewComps/BloodGlucoseReport";
import OverViewReport from "../Components/Reports/OverviewComps/OverViewReport";
import SensorUsageReport from "../Components/Reports/OverviewComps/SensorUsageReport";
import TimeInRangeReport from "../Components/Reports/OverviewComps/TimeInRangeReport";
import AllFilters from "../Components/Common/Filters";
import {fetchBgData} from "../redux/actions/blood-glucose";
import {getDevices} from "../redux/actions/device";
import * as _ from "lodash";
import moment from "moment";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";
import html2pdf from 'html2pdf.js';


const CgmReportComp = () => {
  const { RangePicker } = DatePicker;
    const { userDetails, selectedUser } = useSelector((state) => state.user);
    const handleConvertToPDF = () => {
    const element = document.getElementById('pdfContent'); // Specify the HTML element to convert

    // Configure the options for the PDF conversion
    const options = {
      filename: 'converted.pdf',
      image: { type: 'jpeg', quality: 0.98 }, // or 'png'
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'ledger', orientation: 'landscape' },
      pagebreak: { mode: 'avoid-all', before: '#page2el' }
    };

    // Start the PDF conversion
    html2pdf().set(options).from(element).save();
  };


    // const userData = useSelector((state) => state.user);

  const data = useSelector((state) => {

    return (state.bloodGlucose)});
    console.log("data in state: ", data);

  const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30 days");
  const [showDate, setShowDate] = useState(false);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [organizedData, setOrganizedData] = useState([]);
  const [daysDif, setDaysDif] = useState("");
  const [chartData, setChartData] = useState([]);

  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };


  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      // console.log("FILTER", filter);
      fetchReadings();
    }
  }, [filter, startDate]);

  // useEffect(() => {
  //   transformAverageBgData();
  // }, [data.averageBg]);

  // useEffect(() => {
  //   transformBgReading();
  // }, [data.bgReadings]);

  // useEffect(() => {
  //   if (data.timeRange.length > 0) {
  //     transformTimeRange();
  //   }
  // }, [data.timeRange]);

  const fetchReadings = async () => {

    let data = {};
    if (filter) {
      data.time = filter;
      data.type = 4;

    }
    if (startDate) {

      data.start_date = startDate;
      data.end_date = endDate;
      data.type = 4;
    }
    // if (unit !== null && unit.preferences && unit.preferences.unit !== null) {

      dispatch(fetchBgData(data));
    // }
    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };

  // const transformTimeRange = () => {
  //   let labels = ["<3.9", "3.9-4.9", "5.0-8.0", "8.1-13.3", ">13.3"];
  //   let labelsMgdl = ["<70.24", "70.24-88.3", "90.0-144.1", "145.9-239.5", ">239.5"];
  //   const keys = Object.keys(data.timeRange[0]);
  //   const transformedData = keys.map(key => {
  //     return {
  //       label: unit.preferences && unit.preferences.unit === 0 ? labels[Number(key)] : labelsMgdl[Number(key)],
  //       value: data.timeRange[0][key].value
  //     }
  //   });
  //
  //   setTfTimeRange(transformedData);
  // };


  // const transformAverageBgData = () => {
  //   let transformedData = [];
  //   transformedData = data.averageBg.map((item) => {
  //
  //     let m = moment().hour(item._id).format("hh A");
  //     return {
  //       date: m,
  //       value: item.avg,
  //     };
  //   });
  //   setTfAvgBgData(
  //       _.sortBy(transformedData, (dateObj) => new Date(dateObj.date))
  //   );
  // };

  // const transformBgReading = () => {
  //   let tfBgReadings = data.bgReadings.map((item) => {
  //     let exactTime = item.time.toLocaleString().replace('Z', '');
  //     item.takenTime = item.takenTime === 0 ? "Before meal" : "After Meal";
  //     item.bgValue = item.value;
  //     item.bgValue = item.bgValue && item.bgValue.toFixed(1);
  //     item.time = moment(item.time).format("MMMM-DD-YYYY");
  //     item.clock = moment(exactTime).format("hh:mm:ss a");
  //     return item;
  //   });
  //   setTfBgReadings(tfBgReadings);
  // };

  function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=()=>{
    setFilter("");
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    setIsLoading(true);

    setOrganizedData([]);


  let data = {};



    data.start_date = startDate;
    data.end_date = endDate;



    dispatch(fetchBgData(data));

  if (rangFilter === false){
  let start = findStartDate(filter);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
} else if (rangFilter === true) {

  setFromDate(moment(startDate).format('D MMM YYYY').toString());
  setToDate(moment(endDate).format('D MMM YYYY').toString());
}
  }
  }

  const fetchFilterReadings=(e)=>{
    console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if(filter !== e.target.name){
      setOrganizedData([]);
    }

  let data = {};

    data.time = e.target.name;



    dispatch(fetchBgData(data));

  if (rangFilter === false){
  let start = findStartDate(e.target.name);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
} else if (rangFilter === true) {

}


  }


    return (

        // <div className="bg-white">
        //   <Navbar />
        //   <div className="container-fluid h-100  ">
        //     <div className="row h-100 bg-light rounded mx-1">
        //       <aside className=" col-width p-0 ">
        //         {" "}
        //         <Sidebar />
        //       </aside>
        //       <main className="col">
        //
        //       </main>
        //     </div>
        //   </div>
        // </div>

        <div class="container-fluid h-100 bg-white p-0" >
        {/*<button onClick={handleConvertToPDF}>Convert to PDF</button>
            <Navbar user={userData}/>*/}
            <div class="row  mr-sm-0 bg-light rounded mx-1 pl-0 py-2">
              <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  {/*<OverlayFilter/>*/}
                  <div className="sticky-top">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="chart-heading mt-3 loginNew-HCP-panel">
                        <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                          CGM
                        </span>
                      </div>

                    </div>
                    <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
                    setFilter={setFilter}
                     daysDif={daysDif} fromDate={fromDate} toDate={toDate}
                     startDate={startDate} fetchFilterReadings={fetchFilterReadings}
                     fetchRangeReadings={fetchRangeReadings} startDate={startDate}
                     endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
                     setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} setFilterText={setFilterText} showDays="" showRange={true}/>
                  </div>
                  </div>
                </div>
              </div>

                {/*<aside class="col-12 col-md-2 bg-primary py-3  rounded ">
                    <Sidebar/>
                </aside>*/}
              {/*<main class="col  bg-faded ">*/}
                    {/*<OverViewComp/>*/}



                <div class="row mr-sm-0  mt-2 page" id="pdfContent">
                  <div className="col-12" style={{ height: "100%" }}>
                    <div className="bg-white rounded shadow " style={{ height: "100%", width: "80%", margin: "auto" }}>
                      <CGMReport avg={data} user={userDetails} filter={filter} startDate={startDate} endDate={endDate}/>
                    </div>
                  </div>
                </div>


            {/*</main>*/}

        </div>
    );
};

export default CgmReportComp;
