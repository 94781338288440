export const DefaultTitle = "EOSTRE";

export default {
  default: `${DefaultTitle} | Welcome`,
  login: `${DefaultTitle} | Login`,
  // register: `${DefaultTitle} | Register`,
  // dashboard: `${DefaultTitle} | Dashboard`,
  // history: `${DefaultTitle} | History`,
  // emergency: `${DefaultTitle} | Emergency`,
  // forgotPassword: `${DefaultTitle} | Forgot Password`,
  // resendVerificationEmail: `${DefaultTitle}  | Resend Verification Email`,
};
