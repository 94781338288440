import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";


am4core.useTheme(am4themes_animated);
// Themes end
function BloodGlucose(props) {
  console.log("PROPS :", props);
  const {user, settings} = useSelector((state)=>{return state});
  console.log("Settings", settings);

  const [showGraph, setShowGraph]=useState(false);
  // let chart;
  useEffect(() => {

let chart = am4core.create(props.chartId, am4charts.XYChart);

//

// Increase contrast by taking evey second color
chart.colors.step = 2;

// day
// reading:[value:{},{},{}]

// Add data

// let data = JSON.parse(localStorage.getItem("chartData"));
console.log("DATA", props.data);

chart.data = props.data;


// Create axes
let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
dateAxis.dataFields.category = "time2";
// dateAxis.renderer.minGridDistance = 50;
// dateAxis.baseInterval = {
//   "timeUnit": "minute",
//   "count": 1
// };
// dateAxis.tooltipDateFormat = "HH:mm, d MMMM";
let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"mmol/L":"mgdl";
valueAxis.cursorTooltipEnabled = false;
// Create series
function createAxisAndSeries(field, name, opposite, bullet) {
  // if(chart.yAxes.indexOf(valueAxis) != 0){
  // 	valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
  // }

  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.categoryX = "time2";
  series.strokeWidth = 2;
  // series.yAxis = valueAxis;
  series.name = name;
  series.tooltipText = "{name}: [bold]{valueY}[/]\n date:[bold]{time}[/]";
  series.tensionX = 0.8;
  series.showOnInit = true;

  let interfaceColors = new am4core.InterfaceColorSet();

  switch(bullet) {
    case "triangle":
      let bullet = series.bullets.push(new am4charts.Bullet());
      bullet.width = 12;
      bullet.height = 12;
      bullet.horizontalCenter = "middle";
      bullet.verticalCenter = "middle";

      let triangle = bullet.createChild(am4core.Triangle);
      triangle.stroke = interfaceColors.getFor("background");
      triangle.strokeWidth = 2;
      triangle.direction = "top";
      triangle.width = 12;
      triangle.height = 12;
      break;
    case "rectangle":
      let bullet1 = series.bullets.push(new am4charts.Bullet());
      bullet1.width = 10;
      bullet1.height = 10;
      bullet1.horizontalCenter = "middle";
      bullet1.verticalCenter = "middle";

      let rectangle = bullet1.createChild(am4core.Rectangle);
      rectangle.stroke = interfaceColors.getFor("background");
      rectangle.strokeWidth = 2;
      rectangle.width = 10;
      rectangle.height = 10;
      break;
    default:
      let bullet2 = series.bullets.push(new am4charts.CircleBullet());
      bullet2.circle.stroke = interfaceColors.getFor("background");
      bullet2.circle.strokeWidth = 2;
      break;
  }

  valueAxis.renderer.line.strokeOpacity = 0;
  // valueAxis.renderer.line.strokeWidth = 0;
  // valueAxis.renderer.line.stroke = series.stroke;
  // valueAxis.renderer.labels.template.fill = series.stroke;
  // valueAxis.renderer.opposite = opposite;
  // valueAxis.renderer.line.disabled = true;


}

createAxisAndSeries("monday", "Monday", false, "circle");
createAxisAndSeries("tuesday", "Tuesday", true, "triangle");
createAxisAndSeries("wednesday", "Wednesday", true, "rectangle");
createAxisAndSeries("thursday", "Thursday", true, "pentagonal");
createAxisAndSeries("friday", "Friday", true, "hexagonal");
createAxisAndSeries("saturday", "Saturday", true, "octagonal");
createAxisAndSeries("sunday", "Sunday", true, "rectangle");



//Add Ranges

let range = valueAxis.axisRanges.create();
range.value = 0;
range.endValue = user.userDetails && user.userDetails.user && user.userDetails.user.low?user.userDetails.user.low:0;
range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
range1.value = user.userDetails && user.userDetails.user && user.userDetails.user.high?user.userDetails.user.high:0;
range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;
// Add legend
chart.legend = new am4charts.Legend();


// Add cursor
chart.cursor = new am4charts.XYCursor();

chart.events.on('ready', () => {
  setShowGraph(true);
});




return () => {
      chart.dispose();
    };

}
,
 [props.data]);
 let startDate=moment(new Date(props.startDate)).format('DD-MM-YYYY');
 let endDate=moment(new Date(props.startDate)).add(7, 'days').format('DD-MM-YYYY').toString();
  return (<div className="bg-white rounded shadow pt-3 pb-3 mt-3">
  <p className="pb-3 ml-4">From: {startDate}  To: {endDate}</p>
        <div id={props.chartId}  style={{ width: "100%", minHeight: "500px" }}></div>
  </div>
  );
}

export default BloodGlucose;
