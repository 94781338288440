import React, { useEffect, useState } from "react";

import {Pattern} from "@amcharts/amcharts4/core";
import {useDispatch, useSelector} from "react-redux";





const Ota = () => {

  const [addModal, setAddModal] = useState(false);
  const {userDetails, selectedUser} = useSelector((state) => {return (state.user)});




    return (
<>
{userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)?

        <div className="row mw-100 m-auto h-100 bg-white p-0">
          <div className="col-12 mb-3">
            <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add OTA</button>
          </div>
          <div className="col-12">
            <table className="table table-hover" id="task-table">
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Version</th>
                    <th>Description</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                </tbody>
            </table>
          </div>
        </div>:<div className="row mw-100 m-auto">
          <div className="col-12">
          <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
          </div></div>}
        <div className={`modal big_modal_dialog ${addModal? "d-block modal-fading" : " d-none"}`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"
          >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addHospitalModalTitle">
                    Add OTA
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>setAddModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <form className="pl-3 pr-3">
                  <div className="container my-4 ">


                    <div className="row justify-content-center align-items-center mb-3 text-white">

                      <div className="col-md-2 registration-input-labels"><p className="text-black">Date/Time</p></div>
                      <div className="col-md-10">
                        <input


                          type="date"
                          className="registration-datePicker text-black"

                          required
                        />
                      </div>
                    </div>



                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Version</p></div>
                      <div className="col-md-10">
                        <input
                          type="text"


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Description</p></div>
                      <div className="col-md-10">
                        <textarea
                          type="number"
                          className="registration-form-control text-black"

                          required
                        />

                      </div>
                    </div>







                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels "><p className="text-black">File</p></div>
                      <div className="col-md-10">
                        <div className="styled-select">
                          <input
                          type="file"
                          className="registration-form-control"
                          required
                          />
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary">Add OTA</button>
                  </div>
                </form>
                </div>
              </div>
          </div>
          </>



    );
};

export default Ota;
