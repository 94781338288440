import * as actions from "../actionTypes/admin";

const INITIAL_STATE = {
  patients: [],
  clinicians: [],
  engineers: [],
  admins: [],
  eData:null,
  engeineerEmail:"",
  applicationLogsData:[],
  sessionsData:null,
  modus2:null,
  cliniciansData:[],
  cliniciansSubjects:[],
  allSubjects:[],
  cliniciansSessionsData:[],
  SessParameters:null,

  isLoading: false,

};

export default (state = INITIAL_STATE, action) => {
  // console.log("ACTION", action);
  switch (action.type) {
    case actions.FETCH_PATIENT_START:
      return { ...state, isLoading: true };
    case actions.FETCH_PATIENT_FAILED:
      return {
        ...state,
         isLoading: false,
         patients:[]
        };

    case actions.FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: action.payload,
      };

    case actions.FETCH_CLINICIAN_START:
      return { ...state, isLoading: true };
    case actions.FETCH_CLINICIAN_FAILED:
      return {
        ...state,
         isLoading: false,
         clinicians:[]
        };


    case actions.FETCH_CLINICIAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clinicians: action.payload,
      };


    case actions.FETCH_ENGINEER_START:
      return { ...state, isLoading: true };
    case actions.FETCH_ENGINEER_FAILED:
      return {
        ...state,
         isLoading: false,
         engineers:[]
        };


    case actions.FETCH_ENGINEER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        engineers: action.payload,
      };

    case actions.FETCH_ADMIN_START:
      return { ...state, isLoading: true };
    case actions.FETCH_ADMIN_FAILED:
      return {
        ...state,
         isLoading: false,
         admins:[]
        };


    case actions.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        admins: action.payload,
      };


      case actions.FETCH_ENGINEER_DATA_START:
        return {
          ...state,
          isLoading: true,
          engeineerEmail: action.payload,
          };


      case actions.FETCH_ENGINEER_DATA_FAILED:
        return {
          ...state,
           isLoading: false,
           eData:null,
          };


      case actions.FETCH_ENGINEER_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          eData: action.payload,
        };


        case actions.ADD_CLINICAL_ADMIN_START:
          return {
            ...state,
            isLoading: true,

            };


        case actions.ADD_CLINICAL_ADMIN_FAILED:
          return {
            ...state,
             isLoading: false,
            };


        case actions.ADD_CLINICAL_ADMIN_SUCCESS:
          return {
            ...state,
            isLoading: false,
            // clinicians: clinicians + action.payload,
          };


        case actions.FETCH_SENSOR_LOGS_DATA_START:
          return {
            ...state,
            isLoading: true,
            engeineerEmail: action.payload,
            };


        case actions.FETCH_SENSOR_LOGS_DATA_FAILED:
          return {
            ...state,
             isLoading: false,
             applicationLogsData:null,
            };


        case actions.FETCH_SENSOR_LOGS_DATA_SUCCESS:
          return {
            ...state,
            isLoading: false,
            applicationLogsData: action.payload.logs,
          };

          case actions.FETCH_ENGINEER_SESSIONS_DATA_START:
            return {
              ...state,
              isLoading: true,
              engeineerEmail: action.payload,
              };


          case actions.FETCH_ENGINEER_SESSIONS_DATA_FAILED:
            return {
              ...state,
               isLoading: false,
               sessionsData:null,
              };


          case actions.FETCH_ENGINEER_SESSIONS_DATA_SUCCESS:
            return {
              ...state,
              isLoading: false,
              sessionsData: action.payload,
            };


          case actions.FETCH_CLINICIANS_START:
            return {
              ...state,
              isLoading: true,
              };


          case actions.FETCH_CLINICIANS_FAILED:
            return {
              ...state,
               isLoading: false,
               cliniciansData:null,
              };


          case actions.FETCH_CLINICIANS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              cliniciansData: action.payload,
            };

          case actions.FETCH_SUBJECTS_START:
            return {
              ...state,
              isLoading: true,
              };


          case actions.FETCH_SUBJECTS_FAILED:
            return {
              ...state,
               isLoading: false,
               cliniciansSubjects:null,
              };


          case actions.FETCH_SUBJECTS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              cliniciansSubjects: action.payload,
            };









            case actions.FETCH_ALL_SUBJECTS_START:
              return {
                ...state,
                isLoading: true,
                };


            case actions.FETCH_ALL_SUBJECTS_FAILED:
              return {
                ...state,
                 isLoading: false,
                 allSubjects:null,
                };


            case actions.FETCH_ALL_SUBJECTS_SUCCESS:
              return {
                ...state,
                isLoading: false,
                allSubjects: action.payload,
              };

          case actions.FETCH_CLINICIANS_SESSIONS_DATA_START:
            return {
              ...state,
              isLoading: true,
              };


          case actions.FETCH_CLINICIANS_SESSIONS_DATA_FAILED:
            return {
              ...state,
               isLoading: false,
               cliniciansSessionsData:[],
              };


          case actions.FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS:
            return {
              ...state,
              isLoading: false,
              cliniciansSessionsData: action.payload,
            };

          case actions.FETCH_SESSIONS_PARAMS_START:
            return {
              ...state,
              isLoading: true,
              };


          case actions.FETCH_SESSIONS_PARAMS_FAILED:
            return {
              ...state,
               isLoading: false,
               SessParameters:null,
              };


          case actions.FETCH_SESSIONS_PARAMS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              SessParameters: action.payload,
            };

          case actions.SAVE_APP_MODUS_SUCCESS:
              return {
                ...state,
                isLoading: false,
                modus2: action.payload,
              };



    default:
      return{
        ...state
      }
  }
};
