import React from "react";
import ReactDOM from "react-dom";
import ReactRouter from "./Routes";
import * as serviceWorker from "./serviceWorker";
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Styles/index.scss";
import 'bootstrap/dist/js/bootstrap';
import "./Assets/font-awesome/all.css";

import { Provider } from "react-redux";
import { store } from "./redux/store";

// import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
  <Provider store = { store } >
      <ReactRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
