// import React, { Suspense, useEffect } from "react";
// // import { Route } from "react-router-dom";
// import {useSelector} from "react-redux";
//
// import { BrowserRouter as Router, Navigate, Route } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { fetchUser, updateUserRole } from "./redux/actions/user";
// import { encrypt, decrypt } from "./utils/encryption";
//
// import LoginNewPage from "./Pages/LoginNewPage";
// import Dashboard from "./Components/Dashboard";
//
// import RegistrationNewPage from "./Pages/RegistrationNewPage";
// import LoginPage from "./Pages/LoginPage";
// import RegistrationPage from "./Pages/RegistrationPage";
// import GuestRoute from "./utils/routes/guest-route";
// import AuthenticatedRoute from "./utils/routes/authenticate-route";
// import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
// import ResetPasswordPage from "./Pages/ResetPasswordPage";
// import ResendVerificationEmailPage from "./Pages/ResendVerificationEmailPage";
// import AccountActivationComp from "./Components/Auth/account-activation";
// import DeleteAccount from "./Components/Auth/DeleteAccount";
//
// import MultipleRoles from "./Components/Auth/MultipleRolesComp";
//
//
// import ReportsPage from "./Pages/ReportsPage";
// import SettingPage from "./Pages/SettingPage";
// import SupportPage from "./Pages/SupportPage";
// import AnalyticsPage from "./Pages/Analytics";
// import ErrorPage from "./Pages/Error";
//
//
//
//
//
// // import PatternPage from "./Pages/PatterenPage";
// import TrendsPage from "./Pages/TrendsPage";
// import OverlayPage from "./Pages/OverlayPage";
// import HistoryPage from "./Pages/HistoryPage";
// import ComparePage from "./Pages/ComparePage";
// import StatisticsPage from "./Pages/StatisticsPage";
// import OgpPage from "./Pages/OgpPage";
// import AdminPage from "./Pages/AdminPage";
// import ViewEngineer from "./Components/Admin/ViewEngineers/ViewEngineerComp.jsx";
// import ViewEngineerData from "./Components/Admin/ViewEngineers/ViewEngineerData.jsx";
// // import ViewEngineerData from "./Components/Admin/ViewEngineers/ViewEngineerData";
// import Ota from "./Components/Admin/OTA/Ota";
//
// import SubAdmins from "./Pages/SubAdmins";
// import CliniciansSubjects from "./Components/Admin/Modus/CliniciansSubjects";
// import ViewCliniciansData from "./Components/Admin/Modus/ViewCliniciansData";
// import SessionsParameters from "./Components/Admin/Modus/SessionsParams";
// import AddClinicalAdmin from "./Components/Admin/Modus/AddClinicalAdmin";
// import AllSubjects from "./Components/Admin/Modus/AllSubjects";
//
// import ViewApplicationLogs from "./Components/Admin/SensorLogs/ViewSensorLogs";
// import ViewApplicationLogsData from "./Components/Admin/SensorLogs/ViewSensorLogsData";
//
// import Notifications from "./Components/Admin/Notifications/NotificationsManagement";
//
// import ExportPdf from "./Pages/exportPdf";
// import Maintainance from "./Pages/maintainance";
// import CgmReportComp from "./Pages/CgmReport";
//
// function ReactRouter () {
//   const dispatch = useDispatch();
//   const {user} = useSelector((state)=> {return state.user});
//   // const {role} = useSelector((state)=> {return state.user.userDetails.user});
//   // console.log("USER FROM ROUTE :", user);
//
//   useEffect(() => {
//     if(localStorage.getItem("Apple")){
//       let user = localStorage.getItem("Apple");
//       user = decrypt(user);
//       user = JSON.parse(user);
//       // console.log("USER IN ROUTE :", user);
//       dispatch(updateUserRole(user));
//     } else {
//       dispatch(fetchUser());
//
//     }
//   }, []);
//
//     return (
//       <React.Fragment>
//       <Router>
//       <Suspense fallback={() => <div>loading...</div>}>
//           <Route>
//             <GuestRoute exact path="/" component={LoginNewPage} />
//             <GuestRoute exact path={"/auth/login"} component={LoginNewPage} />
//             <GuestRoute exact path={"/user/activate-account"} component={AccountActivationComp}/>
//             <GuestRoute exact path={"/user/role"} component={MultipleRoles}/>
//
//             <GuestRoute exact path="/RegistrationOld" component={RegistrationPage} />
//             <GuestRoute exact path="/Login" component={LoginNewPage} />
//             <GuestRoute exact path="/Registration" component={RegistrationNewPage} />
//             <GuestRoute exact path="/forgetPassword" component={ForgetPasswordPage}/>
//             <GuestRoute exact path="/auth/resetPassword" component={ResetPasswordPage}/>
//             <GuestRoute exact path="/auth/resendVerificationEmail" component={ResendVerificationEmailPage}/>
//             <AuthenticatedRoute exact path="/Report" component={ReportsPage} />
//             {/*<AuthenticatedRoute exact path="/Pattern" component={PatternPage} />
//             <AuthenticatedRoute exact path="/Trend" component={TrendsPage} />*/}
//             <AuthenticatedRoute exact path="/Overlay" component={OverlayPage} />
//             <AuthenticatedRoute exact path="/History" component={HistoryPage} />
//             <AuthenticatedRoute exact path="/Compare" component={ComparePage} />
//             <AuthenticatedRoute exact path="/Statistics" component={StatisticsPage} />
//             <AuthenticatedRoute exact path="/Ogp" component={OgpPage} />
//             <AuthenticatedRoute exact path="/Ota" component={Ota} />
//             <AuthenticatedRoute exact path="/cgm" component={CgmReportComp} />
//             <AuthenticatedRoute exact path="/auth/deleteUser" component={DeleteAccount} />
//
//
//             {/* <AuthenticatedRoute exact path="/admin/viewEngineerData" component={ViewEngineerData} /> */}
//
//             <AuthenticatedRoute
//             exact
//             path={"/dashboard"}
//             component={Dashboard}
//           />
//           <AuthenticatedRoute exact path="/admin/users" component={AdminPage} />
//           <AuthenticatedRoute exact path="/admin/EngineerData/:app" component={ViewEngineer} />
//           <AuthenticatedRoute exact path="/admin/EngineerData/EngineerSession/:id/:email/:app" component={ViewEngineerData} />
//           <AuthenticatedRoute exact path="/admin/Clinics" component={SubAdmins} />
//           <AuthenticatedRoute exact path="/admin/Clinics/List" component={SubAdmins} />
//
//           <AuthenticatedRoute exact path="/admin/Clinicians/:sId" component={CliniciansSubjects} />
//           <AuthenticatedRoute exact path="/admin/CliniciansSessionsData/:_id/:subId/:shortId" component={ViewCliniciansData} />
//           <AuthenticatedRoute exact path="/SessionsParams" component={SessionsParameters} />
//           <AuthenticatedRoute exact path="/admin/Clinics/SessionsParams" component={SessionsParameters} />
//
//           <AuthenticatedRoute exact path="/add-clinical-admin" component={AddClinicalAdmin} />
//           <AuthenticatedRoute exact path="/Subjects" component={AllSubjects} />
//           <AuthenticatedRoute exact path="/admin/Clinics/AllSubjects" component={AllSubjects} />
//           <AuthenticatedRoute exact path="/admin/applicationLogs/:app" component={ViewApplicationLogs} />
//           <AuthenticatedRoute exact path="/admin/applicationLogs/applicationLogsData/:id/:email/:app" component={ViewApplicationLogsData} />
//
//           <AuthenticatedRoute exact path="/admin/notifications" component={Notifications} />
//           <AuthenticatedRoute exact path="/admin/ota" component={Ota} />
//           <AuthenticatedRoute exact path="/admin/Analytics" component={AnalyticsPage} />
//           <AuthenticatedRoute exact path="/admin/Errors" component={ErrorPage} />
//             <AuthenticatedRoute exact path="/Setting" component={SettingPage} />
//             <AuthenticatedRoute exact path="/Support" component={SupportPage} />
//             <AuthenticatedRoute exact path={"/export-data"} component={ExportPdf} />
//
//
//             <Navigate from="*" to="/auth/login" />
//           </Route>
//         </Suspense>
//       </Router>
//       <ToastContainer />
//       </React.Fragment>
//     );
// }
//
// export default ReactRouter;

// old try

import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUser, updateUserRole } from "./redux/actions/user";
import { encrypt, decrypt } from "./utils/encryption";

import LoginNewPage from "./Pages/LoginNewPage";
import Dashboard from "./Components/Dashboard";
import RegistrationNewPage from "./Pages/RegistrationNewPage";
import LoginPage from "./Pages/LoginPage";
import RegistrationPage from "./Pages/RegistrationPage";
import GuestRoute from "./utils/routes/guest-route";
import AuthenticatedRoute from "./utils/routes/authenticate-route";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import ResendVerificationEmailPage from "./Pages/ResendVerificationEmailPage";
import AccountActivationComp from "./Components/Auth/account-activation";
import DeleteAccount from "./Components/Auth/DeleteAccount";
import MultipleRoles from "./Components/Auth/MultipleRolesComp";
import ReportsPage from "./Pages/ReportsPage";
import SettingPage from "./Pages/SettingPage";
import SupportPage from "./Pages/SupportPage";
import AnalyticsPage from "./Pages/Analytics";
import ErrorPage from "./Pages/Error";
import TrendsPage from "./Pages/TrendsPage";
import OverlayPage from "./Pages/OverlayPage";
import HistoryPage from "./Pages/HistoryPage";
import ComparePage from "./Pages/ComparePage";
import StatisticsPage from "./Pages/StatisticsPage";
import OgpPage from "./Pages/OgpPage";
import AdminPage from "./Pages/AdminPage";
import ViewEngineer from "./Components/Admin/ViewEngineers/ViewEngineerComp.jsx";
import ViewEngineerData from "./Components/Admin/ViewEngineers/ViewEngineerData.jsx";
import Ota from "./Components/Admin/OTA/Ota";
import SubAdmins from "./Pages/SubAdmins";
import CliniciansSubjects from "./Components/Admin/Modus/CliniciansSubjects";
import ViewCliniciansData from "./Components/Admin/Modus/ViewCliniciansData";
import SessionsParameters from "./Components/Admin/Modus/SessionsParams";
import AddClinicalAdmin from "./Components/Admin/Modus/AddClinicalAdmin";
import AllSubjects from "./Components/Admin/Modus/AllSubjects";
import ViewApplicationLogs from "./Components/Admin/SensorLogs/ViewSensorLogs";
import ViewApplicationLogsData from "./Components/Admin/SensorLogs/ViewSensorLogsData";
import Notifications from "./Components/Admin/Notifications/NotificationsManagement";
import ExportPdf from "./Pages/exportPdf";
import Maintainance from "./Pages/maintainance";
import CgmReportComp from "./Pages/CgmReport";

function ReactRouter() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (localStorage.getItem("Apple")) {
      let user = localStorage.getItem("Apple");
      // user = decrypt(user);
      user = decrypt(user).then((decrypted)=>{
        console.log("Decreypted :", decrypted);
        user = JSON.parse(decrypted);
        console.log("User", user);
        // localStorage.setItem("User", user);
        console.log("USER FROM APP.JS", user);
        dispatch(updateUserRole(user));
      });
      // user = JSON.parse(user);

    } else {
      dispatch(fetchUser());
    }
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Suspense fallback={() => <div>loading...</div>}>
          <Routes>

            {/*<Route path="/user/activate-account" element={<AccountActivationComp />} />*/}
            <Route
          path="/user/activate-account"
          element={
            <GuestRoute children={<AccountActivationComp/>} path="/user/activate-account"/>

          }
        />
            <Route
          path="/user/role"
          element={
            <GuestRoute children={<MultipleRoles/>} path="/user/role"/>

          }
        />

            <Route
          path="/RegistrationOld"
          element={
            <GuestRoute children={<RegistrationPage/>} path="/RegistrationOld"/>

          }
        />

            <Route
          path="/Login"
          element={
            <GuestRoute children={<LoginNewPage/>} path="/Login"/>

          }
        />
            <Route
          path="/Registration"
          element={
            <GuestRoute children={<RegistrationNewPage/>} path="/Registration"/>

          }
        />
            <Route
          path="/forgetPassword"
          element={
            <GuestRoute children={<ForgetPasswordPage/>} path="/forgetPassword"/>

          }
        />
            <Route path="/auth/resetPassword" element={<ResetPasswordPage />} />
            <Route
          path="/auth/resetPassword"
          element={
            <GuestRoute children={<ResetPasswordPage/>} path="/auth/resetPassword"/>

          }
        />

            <Route
          path="/auth/resendVerificationEmail"
          element={
            <GuestRoute children={<ResendVerificationEmailPage/>} path="/auth/resendVerificationEmail"/>

          }
        />
            <Route
          path="/auth/login"
          element={
            <GuestRoute children={<LoginNewPage/>} path="/auth/login"/>

          }
        />
        {/*AuthenticatedRoute*/}
            <Route
          path="/dashboard"
          element={
            <AuthenticatedRoute>
              <Dashboard />
            </AuthenticatedRoute>
          }
        />

        <Route
        path="/Report"
        element={
        <AuthenticatedRoute>
          <ReportsPage />
        </AuthenticatedRoute>
        }
        />




              <Route
              path="/Overlay"
              element={
              <AuthenticatedRoute>
                <OverlayPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/History"
              element={
              <AuthenticatedRoute>
                <HistoryPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Compare"
              element={
              <AuthenticatedRoute>
                <ComparePage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Statistics"
              element={
              <AuthenticatedRoute>
                <StatisticsPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Ogp"
              element={
              <AuthenticatedRoute>
                <OgpPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Ota"
              element={
              <AuthenticatedRoute>
                <Ota />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/cgm"
              element={
              <AuthenticatedRoute>
                <CgmReportComp />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/auth/deleteUser"
              element={
              <AuthenticatedRoute>
                <DeleteAccount />
              </AuthenticatedRoute>
              }
              />


              <Route
              path="/admin/users"
              element={
              <AuthenticatedRoute>
                <AdminPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/EngineerData/:app"
              element={
              <AuthenticatedRoute>
                <ViewEngineer />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/EngineerData/EngineerSession/:id/:email/:app"
              element={
              <AuthenticatedRoute>
                <ViewEngineerData />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Clinics"
              element={
              <AuthenticatedRoute>
                <SubAdmins />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Clinics/List"
              element={
              <AuthenticatedRoute>
                <SubAdmins />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Clinicians/:sId"
              element={
              <AuthenticatedRoute>
                <CliniciansSubjects />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/CliniciansSessionsData/:_id/:subId/:shortId"
              element={
              <AuthenticatedRoute>
                <ViewCliniciansData />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/SessionsParams"
              element={
              <AuthenticatedRoute>
                <SessionsParameters />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Clinics/SessionsParams"
              element={
              <AuthenticatedRoute>
                <SessionsParameters />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/add-clinical-admin"
              element={
              <AuthenticatedRoute>
                <AddClinicalAdmin />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Subjects"
              element={
              <AuthenticatedRoute>
                <AllSubjects />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Clinics/AllSubjects"
              element={
              <AuthenticatedRoute>
                <AllSubjects />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/applicationLogs/:app"
              element={
              <AuthenticatedRoute>
                <ViewApplicationLogs />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/applicationLogs/applicationLogsData/:id/:email/:app"
              element={
              <AuthenticatedRoute>
                <ViewApplicationLogsData />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/notifications"
              element={
              <AuthenticatedRoute>
                <Notifications />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/ota"
              element={
              <AuthenticatedRoute>
                <Ota />
              </AuthenticatedRoute>
              }
              />


              <Route
              path="/admin/Analytics"
              element={
              <AuthenticatedRoute>
                <AnalyticsPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/admin/Errors"
              element={
              <AuthenticatedRoute>
                <ErrorPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Setting"
              element={
              <AuthenticatedRoute>
                <SettingPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/Support"
              element={
              <AuthenticatedRoute>
                <SupportPage />
              </AuthenticatedRoute>
              }
              />

              <Route
              path="/export-data"
              element={
              <AuthenticatedRoute>
                <ExportPdf />
              </AuthenticatedRoute>
              }
              />
              <Route path="*" element={<Navigate to="/auth/login" />} />

          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default ReactRouter;


/// new try

// import React, { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { fetchUser, updateUserRole } from "./redux/actions/user";
// import { encrypt, decrypt } from "./utils/encryption";
//
// import LoginNewPage from "./Pages/LoginNewPage";
// import Dashboard from "./Components/Dashboard";
//
// import RegistrationNewPage from "./Pages/RegistrationNewPage";
// import LoginPage from "./Pages/LoginPage";
// import RegistrationPage from "./Pages/RegistrationPage";
// import GuestRoute from "./utils/routes/GuestRoute";
// import AuthenticatedRoute from "./utils/routes/AuthenticatedRoute";
// import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
// import ResetPasswordPage from "./Pages/ResetPasswordPage";
// import ResendVerificationEmailPage from "./Pages/ResendVerificationEmailPage";
// import AccountActivationComp from "./Components/Auth/account-activation";
// import DeleteAccount from "./Components/Auth/DeleteAccount";
//
// import MultipleRoles from "./Components/Auth/MultipleRolesComp";
//
//
// import ReportsPage from "./Pages/ReportsPage";
// import SettingPage from "./Pages/SettingPage";
// import SupportPage from "./Pages/SupportPage";
// import AnalyticsPage from "./Pages/Analytics";
// import ErrorPage from "./Pages/Error";
//
// import TrendsPage from "./Pages/TrendsPage";
// import OverlayPage from "./Pages/OverlayPage";
// import HistoryPage from "./Pages/HistoryPage";
// import ComparePage from "./Pages/ComparePage";
// import StatisticsPage from "./Pages/StatisticsPage";
// import OgpPage from "./Pages/OgpPage";
// import AdminPage from "./Pages/AdminPage";
// import ViewEngineer from "./Components/Admin/ViewEngineers/ViewEngineerComp.jsx";
// import ViewEngineerData from "./Components/Admin/ViewEngineers/ViewEngineerData.jsx";
// import Ota from "./Components/Admin/OTA/Ota";
//
// import SubAdmins from "./Pages/SubAdmins";
// import CliniciansSubjects from "./Components/Admin/Modus/CliniciansSubjects";
// import ViewCliniciansData from "./Components/Admin/Modus/ViewCliniciansData";
// import SessionsParameters from "./Components/Admin/Modus/SessionsParams";
// import AddClinicalAdmin from "./Components/Admin/Modus/AddClinicalAdmin";
// import AllSubjects from "./Components/Admin/Modus/AllSubjects";
//
// import ViewApplicationLogs from "./Components/Admin/SensorLogs/ViewSensorLogs";
// import ViewApplicationLogsData from "./Components/Admin/SensorLogs/ViewSensorLogsData";
//
// import Notifications from "./Components/Admin/Notifications/NotificationsManagement";
//
// import ExportPdf from "./Pages/exportPdf";
// import Maintainance from "./Pages/maintainance";
// import CgmReportComp from "./Pages/CgmReport";
//
// function ReactRouter() {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.user);
//
//   useEffect(() => {
//     if (localStorage.getItem("Apple")) {
//       let user = localStorage.getItem("Apple");
//       user = decrypt(user);
//       user = JSON.parse(user);
//       dispatch(updateUserRole(user));
//     } else {
//       dispatch(fetchUser());
//     }
//   }, []);
//
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to="/auth/login" />} />
//         <Route path="/auth/login" element={<LoginNewPage />} />
//         <Route path="/user/activate-account" element={<AccountActivationComp />} />
//         <Route path="/user/role" element={<MultipleRoles />} />
//         <Route path="/RegistrationOld" element={<RegistrationPage />} />
//         <Route path="/Login" element={<LoginNewPage />} />
//         <Route path="/Registration" element={<RegistrationNewPage />} />
//         <Route path="/forgetPassword" element={<ForgetPasswordPage />} />
//         <Route path="/auth/resetPassword" element={<ResetPasswordPage />} />
//         <Route path="/auth/resendVerificationEmail" element={<ResendVerificationEmailPage />} />
//
//         <Route element={<AuthenticatedRoute />}>
//           <Route path="/Report" element={<ReportsPage />} />
//           <Route path="/Overlay" element={<OverlayPage />} />
//           <Route path="/History" element={<HistoryPage />} />
//           <Route path="/Compare" element={<ComparePage />} />
//           <Route path="/Statistics" element={<StatisticsPage />} />
//           <Route path="/Ogp" element={<OgpPage />} />
//           <Route path="/Ota" element={<Ota />} />
//           <Route path="/cgm" element={<CgmReportComp />} />
//           <Route path="/auth/deleteUser" element={<DeleteAccount />} />
//
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/admin/users" element={<AdminPage />} />
//           <Route path="/admin/EngineerData/:app" element={<ViewEngineer />} />
//           <Route path="/admin/EngineerData/EngineerSession/:id/:email/:app" element={<ViewEngineerData />} />
//           <Route path="/admin/Clinics" element={<SubAdmins />} />
//           <Route path="/admin/Clinics/List" element={<SubAdmins />} />
//           <Route path="/admin/Clinicians/:sId" element={<CliniciansSubjects />} />
//           <Route path="/admin/CliniciansSessionsData/:_id/:subId/:shortId" element={<ViewCliniciansData />} />
//           <Route path="/SessionsParams" element={<SessionsParameters />} />
//           <Route path="/admin/Clinics/SessionsParams" element={<SessionsParameters />} />
//
//           <Route path="/add-clinical-admin" element={<AddClinicalAdmin />} />
//           <Route path="/Subjects" element={<AllSubjects />} />
//           <Route path="/admin/Clinics/AllSubjects" element={<AllSubjects />} />
//           <Route path="/admin/applicationLogs/:app" element={<ViewApplicationLogs />} />
//           <Route path="/admin/applicationLogs/applicationLogsData/:id/:email/:app" element={<ViewApplicationLogsData />} />
//
//           <Route path="/admin/notifications" element={<Notifications />} />
//           <Route path="/admin/ota" element={<Ota />} />
//           <Route path="/admin/Analytics" element={<AnalyticsPage />} />
//           <Route path="/admin/Errors" element={<ErrorPage />} />
//           <Route path="/Setting" element={<SettingPage />} />
//           <Route path="/Support" element={<SupportPage />} />
//           <Route path="/export-data" element={<ExportPdf />} />
//         </Route>
//
//         <Route element={<GuestRoute />}>
//           <Route path="/" element={<LoginNewPage />} />
//         </Route>
//       </Routes>
//       <ToastContainer />
//     </Router>
//   );
// }
//
// export default ReactRouter;
