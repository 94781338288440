import React from 'react';
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";
import SupportComp from '../Components/Support/SupportComp';
import Maintainance from "./maintainance";

const SupportPage = () => {
    return (
        <div>
             <div class="container-fluid h-100 bg-white">
      
    <div class="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main class="col  bg-faded ">
        <Maintainance/>
         </main>
    </div>
</div>
        </div>
    );
};

export default SupportPage;
