// import React, { Component } from "react";
// import { connect } from "react-redux";
//
// import { Route, Redirect } from "react-router-dom";
//
// class GuestRoute extends Component {
//   render() {
//
//     const { isAuthenticated, component: Component, ...rest } = this.props;
//     if (this.props.path === "/" && !isAuthenticated) {
//       return <Redirect to="/auth/login" />;
//     } else if(isAuthenticated && localStorage.getItem("url") !== "/dashboard" &&
//         localStorage.getItem("url") !== "/auth/login" && localStorage.getItem("url") !== "/") {
//
//     }
//     return (
//       <div>
//         <Route
//           {...rest}
//           render={(props) =>
//             !isAuthenticated ? (
//               <Component {...props} />
//             )
//             :(
//               <Redirect to="/dashboard" />
//             )
//           }
//         />
//       </div>
//     );
//   }
// }
//
// function mapStateToProps(state) {
//   const { token } = state.auth;
//
//   return {
//     isAuthenticated: !!token,
//   };
// }
//
// export default connect(mapStateToProps)(GuestRoute);

// new try
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, Outlet } from "react-router-dom";

const GuestRoute = (props) => {
  const isAuthenticated = useSelector(state => !!state.auth.token);
  useEffect(() => {
console.log("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  if (props.path === "/" && !isAuthenticated) {
    return <Navigate to="/auth/login" />;
  } else if (isAuthenticated && localStorage.getItem("url") !== "/dashboard" &&
    localStorage.getItem("url") !== "/auth/login" && localStorage.getItem("url") !== "/") {
    // You can handle additional conditions here if needed.
  }

  return isAuthenticated === false && !!localStorage.getItem("EostreAuthToken") === false? props.children : <Navigate to="/dashboard" />;
};

export default GuestRoute;
